import { FC } from 'react';
import { Box, Typography } from '@okta/odyssey-react-mui';
import { useTranslation } from '@okta/tools.react-i18n';

type FooterType = 'ISV' | 'Analyst';

export const ToriiFooter: FC<{ footerType: FooterType }> = ({ footerType }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ paddingBottom: '2rem', textAlign: 'center' }}>
      {footerType === 'Analyst' && (
        <>
          <Typography>{t('footer.analyst.dates')}</Typography>
          <Typography>{t('footer.analyst.priorities')}</Typography>
        </>
      )}
    </Box>
  );
};
