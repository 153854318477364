/**
 * @author Eric Carroll
 */
import { _, View, createButton } from 'okta';
import ConfirmationDialogModel from '../models/ConfirmationDialogModel';
import ConfirmationDialog from './ConfirmationDialog';
export default View.extend({
  initialize() {
    const _model = this.model;

    this.add(
      createButton({
        icon: 'delete-16-gray',
        enabled: _.contains(['DISCARDED', 'NOT_SUBMITTED'], this.model.get('status')),
        click() {
          const dialogModel = new ConfirmationDialogModel({
            title: 'Delete integration',
            text: 'Are you sure you want to delete ' + _model.get('appName') + '?',
            bannerText: 'This action cannot be undone.',
            buttonText: 'Delete',
            bannerType: 'error',
            buttonType: 'error',
            showCancelButton: true,
          });
          const dialog = new ConfirmationDialog({
            model: dialogModel,
            action() {
              _model.deleteApp();
            },
          });

          dialog.render();
        },
      })
    );
  },
});
