/**
 * @author Eric Carroll
 */
import { $, Controller } from 'okta';
import React from 'react';
import App from '../models/App';
import AppDashboardView from '../views/AppDashboardView';
import { LandingPage } from '../views/landingPage/LandingPage';
import { LandingPageError } from '../views/landingPage/LandingPageError';
import { createWrapperWithUserProvider } from '../views/utils/ReactUtils';
import BannerView from '../views/BannerView';
import DeprecateSubmissionBannerMessageView from '../views/DeprecateSubmissionBannerMessageView';


export default Controller.extend({
  /**
   * Fetch the apps the ISV has access to.
   * If we can fetch the apps, and no progress has been made on an app, show the getting-started landing page.
   * If we can fetch the apps, and progress has been made, show the apps dashboard.
   * If we cannot fetch the apps, display the error landing page.
   */
  initialize() {
    // If there's no authenticated user, show the signed-out landing page.
    if (!this.options.currentUser) {
      this._showLandingPage(undefined);
    } else {
      this._verifyMigratedSubmissions();
      this.apps = new App.Collection();
      this._fetchApps();
      this._showOinWizardBanner();
    }
  },

  /**
   * Event handler for the Apps collection.
   *
   * Check to see if all the apps have been deleted. If so, fetch the apps again
   * and re-render the LandingPage
   */
  appsEvents: {
    remove() {
      if (this.apps.size() === 0) {
        this._fetchApps();
      }
    },
  },

  /**
   * Fetch the apps and loads the LangingPage or AppDashboardView
   * @private
   */
  _fetchApps() {
    this.apps.fetch().then(
      () => {
        this._onAppSuccess();
      },
      (failureResponse) => {
        this._onAppError(failureResponse.status);
      },
    );
  },

  /**
   * Check if any apps in the org are migrated to the new OIN Wizard and mark them as deprecated
   * @private
   */
  _verifyMigratedSubmissions() {
    $.ajax({
      url: '/api/v1/apps/deprecate',
      method: 'POST',
      datatype: 'json',
    })
      .fail((response) => {
        const responseJSON = response.responseJSON;
        const message =
            (responseJSON && (responseJSON.message || responseJSON.errorSummary || responseJSON.statusText)) ||
            'An unknown error occurred';

        self.showError(message);
      });
  },
  /**
   * If progress has been made on an app, show the apps dashboard.
   * Otherwise, show the getting-started landing page.
   * @private
   */
  _onAppSuccess() {
    // if the ISV has started on an integration, show the app dashboard
    if (this.apps.isStarted()) {
      this._showAppDashboard(this.apps, this.options.currentUser);
    }

    // if not, show the getting-starting landing page
    // by definition, if we're showing getting-started, there is one app with one version
    else {
      const app = this.apps.length ? this.apps.at(0) : undefined;
      const appConfigurations = app ? app.get('appConfigurations') : [];
      const appConfiguration = appConfigurations.length ? appConfigurations[0] : undefined;

      this._showLandingPage(appConfiguration);
    }
  },

  /**
   * If we were forbidden, we'll show the cannot-access error page.
   * @param userStatus Status returned when we fetched the user
   * @param appsStatus Status returned when we fetched the apps
   * @private
   */
  _onAppError(appsStatus) {
    // forbidden - look at the current user to figure out why, and show the error view
    if (appsStatus === 403) {
      // did get user - they just don't have access
      if (this.options.currentUser !== undefined) {
        this._showNoAccessPage();
      }

      // also did't get user (shouldn't happen if apps says forbidden, but easy enough to handle)
      else {
        this._showLandingPage(undefined);
      }
    }
  },

  /**
   * Show the app dashboard
   * @param apps Apps collection
   * @private
   */
  _showAppDashboard(apps, user) {
    this.add(new AppDashboardView({ el: '.torii-landing-page-content', collection: apps, user: user }));
  },

  /**
   * Show the landing page.
   * If signedIn is false, the landing page will provide buttons for signing in.
   * @param version The app's one version, if undefined the user is not signed in
   * @private
   */
  _showLandingPage(version) {
    this.add(
      createWrapperWithUserProvider({
        el: '.torii-landing-page-content',
        children: React.createElement(LandingPage, { appIntegrationId: version?.get('id') }),
        user: this.options.currentUser,
      }),
    );
  },

  /**
   * Show the no-access view - for end-user and disabled-user.
   * @param user User model
   * @private
   */
  _showNoAccessPage() {
    this.add(
      createWrapperWithUserProvider({
        el: '.torii-landing-page-content',
        children: React.createElement(LandingPageError),
        user: this.options.currentUser,
      }),
    );
  },

  _showOinWizardBanner() {
    if (!this.canManageAppSubmissions) {
      const bannerView = new BannerView({
        el: '#torii-banner',
        messageView: DeprecateSubmissionBannerMessageView,
        fixedPageElements: ['.torii-flex'],
      });

      bannerView.render();
    }
  },
});
