/**
 * author: Azhar Iqbal
 */
import ComplexValidators from './ComplexValidators';
import SimpleValidators from './SimpleValidators';

const GENERAL = 'GENERAL';
const SAML = 'SAML';
const SCIM = 'SCIM';
const OIDC = 'OIDC';
const OAUTH = 'OAUTH';
const CONNECTOR = 'CONNECTOR';

const validations = {
  'appConfigurationJson.appInfo.notInOan.name': {
    displayName: 'App name',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isValidAppName],
    },
  },
  'appConfigurationJson.appInfo.inOan.name': {
    displayName: 'App name',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isValidAppName],
    },
  },
  'appConfigurationJson.appInfo.updatedInfo': {
    displayName: 'Updated Info',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.sizeLessThan1024],
    },
  },
  'appConfigurationJson.appInfo.notInOan.website': {
    displayName: 'App website',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
    },
  },
  'appConfigurationJson.appInfo.notInOan.description': {
    displayName: 'App description',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.sizeLessThan1024],
    },
  },
  'appConfigurationJson.customerSupport.publicSupportContacts': {
    displayName: 'Public support contacts',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.supportContactsHasNoEmptyStrings],
      complex: [ComplexValidators.verifySupportContacts],
    },
  },
  'appConfigurationJson.customerSupport.escalationSupportContact': {
    displayName: 'Escalation support contact',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isEmailOrPhone],
    },
  },
  'appConfigurationJson.testAccount.url': {
    displayName: 'Test account URL',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isNonOktaUrl],
    },
  },
  'appConfigurationJson.testAccount.user': {
    displayName: 'Test account username/email',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isEmailOrUsername],
    },
  },
  'appConfigurationJson.testAccount.password': {
    displayName: 'Test account password',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.sizeLessThan255],
    },
  },
  'appConfigurationJson.testAccount.instructions': {
    displayName: 'Additional instructions',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.sizeLessThan1024],
    },
  },
  'samlConfigurationJson.appInstanceUrl': {
    displayName: 'App instance URL',
    section: SAML,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
    },
  },
  'samlConfigurationJson.bigBang.backdoorUrl': {
    displayName: 'Big bang backdoor URL',
    section: SAML,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrlOrNa],
    },
  },
  'samlConfigurationJson.signInFlow.spInitiatedUrl': {
    displayName: 'URL used to initiate the SP flow',
    section: SAML,
    validators: {
      branch: {
        branchField: 'samlConfigurationJson.signInFlow.sp',
        branches: {
          true: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrlOrNa],
        },
      },
    },
  },
  'samlConfigurationJson.signInFlow.spDescription': {
    displayName: 'SP flow description',
    section: SAML,
    validators: {
      branch: {
        branchField: 'samlConfigurationJson.signInFlow.sp',
        branches: {
          true: [SimpleValidators.sizeLessThan1024, SimpleValidators.stringIsNotEmpty],
        },
      },
    },
  },
  'samlConfigurationJson.configGuideLink': {
    displayName: 'Configuration guide link',
    section: SAML,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
    },
  },
  'samlConfigurationJson.appInstanceProperties': {
    displayName: 'ACS URL and Audience URI Variables',
    section: SAML,
    validators: {
      branch: {
        branchField: 'samlConfigurationJson.doesAcsUrlVaryPerTenant',
        branches: {
          YES: [SimpleValidators.arrayIsNotEmpty],
        },
      },
    },
  },
  'samlConfigurationJson.acsUrl': {
    displayName: 'Dynamic ACS URL',
    section: SAML,
    validators: {
      simple: [SimpleValidators.sizeLessThan255],
      branch: {
        branchField: 'samlConfigurationJson.doesAcsUrlVaryPerTenant',
        branches: {
          YES: [SimpleValidators.stringIsNotEmpty, SimpleValidators.isDynamicUrl],
        },
      },
      complex: [ComplexValidators.validSamlVariablesUsed],
    },
  },
  'samlConfigurationJson.audienceUri': {
    displayName: 'Dynamic Audience URI',
    section: SAML,
    validators: {
      simple: [SimpleValidators.sizeLessThan255],
      branch: {
        branchField: 'samlConfigurationJson.doesAcsUrlVaryPerTenant',
        branches: {
          YES: [SimpleValidators.stringIsNotEmpty],
        },
      },
      complex: [ComplexValidators.validSamlVariablesUsed],
    },
  },
  'samlConfigurationJson.sloAppInstanceProperties': {
    displayName: 'SLO URL Variables',
    section: SAML,
    validators: {
      branch: {
        branchField: 'samlConfigurationJson.spSingleSignOut.supported',
        branches: {
          YES: {
            branchField: 'samlConfigurationJson.spSingleSignOut.doesSloUrlVaryPerTenant',
            branches: {
              YES: [SimpleValidators.arrayIsNotEmpty],
            },
          },
        },
      },
    },
  },
  'samlConfigurationJson.spSingleSignOut.sloUrl': {
    displayName: 'Dynamic SLO URL',
    section: SAML,
    validators: {
      simple: [SimpleValidators.sizeLessThan255],
      branch: {
        branchField: 'samlConfigurationJson.spSingleSignOut.supported',
        branches: {
          YES: {
            branchField: 'samlConfigurationJson.spSingleSignOut.doesSloUrlVaryPerTenant',
            branches: {
              YES: [SimpleValidators.stringIsNotEmpty, SimpleValidators.isDynamicUrl],
            },
          },
        },
      },
      complex: [ComplexValidators.validSamlVariablesUsed],
    },
  },
  'samlConfigurationJson.demoVideoLink': {
    displayName: 'Demo video link',
    section: SAML,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
    },
  },
  'samlConfigurationJson.testResultLink': {
    displayName: 'Test results screenshot link',
    section: SAML,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
    },
  },
  'samlConfigurationJson.attributeStatements': {
    displayName: 'SAML Attribute Statements',
    section: SAML,
    validators: {
      simple: [SimpleValidators.statementsNoDuplicateName],
    },
  },
  'samlConfigurationJson.groupAttributeStatements': {
    displayName: 'SAML Group Attribute Statements',
    section: SAML,
    validators: {
      simple: [SimpleValidators.statementsNoDuplicateName],
    },
  },
  'scimConfigurationJson.appInstanceUrl': {
    displayName: 'App instance URL',
    section: SCIM,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
    },
  },
  'appConfigurationJson.customerSupport.configGuideLink': {
    displayName: 'Configuration guide link',
    section: GENERAL,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
    },
  },
  'scimConfigurationJson.fixedUrl': {
    displayName: 'Base URL',
    section: SCIM,
    validators: {
      simple: [SimpleValidators.sizeLessThan255],
      branch: {
        branchField: 'scimConfigurationJson.doesFixedUrlVaryByTenant',
        branches: {
          YES: [SimpleValidators.stringIsNotEmpty, SimpleValidators.isDynamicUrl],
          NO: [SimpleValidators.isUrlOrNa],
        },
      },
      complex: [ComplexValidators.validScimVariablesUsed],
    },
  },
  'scimConfigurationJson.appInstanceProperties': {
    displayName: 'Base URL Variables',
    section: SCIM,
    validators: {
      branch: {
        branchField: 'scimConfigurationJson.doesFixedUrlVaryByTenant',
        branches: {
          YES: [SimpleValidators.arrayIsNotEmpty],
        },
      },
    },
  },
  'scimConfigurationJson.customPatchBatchSize': {
    displayName: 'Custom Patch Batch Size',
    section: SCIM,
    validators: {
      simple: [(val) => SimpleValidators.isEmptyOrNumberWithinBounds(val, 100, 1000)],
    },
  },
  'scimConfigurationJson.demoVideoLink': {
    displayName: 'Demo video link',
    section: SCIM,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
    },
  },
  'scimConfigurationJson.runscopeTestLink': {
    displayName: 'Runscope test results link',
    section: SCIM,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
    },
  },
  'scimConfigurationJson.runscopeCrudTestLink': {
    displayName: 'Runscope CRUD test results link',
    section: SCIM,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
    },
  },
  'oidcConfigurationJson.appInstanceUrl': {
    displayName: 'App instance URL',
    section: OIDC,
    validators: {
      simple: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
    },
  },
  'oidcConfigurationJson.redirectURIs': {
    displayName: 'Redirect URI',
    section: OIDC,
    validators: {
      simple: [SimpleValidators.arrayHasNoEmptyStrings, SimpleValidators.arrayHasNoDuplicates],
      branch: {
        branchField: 'oidcConfigurationJson.redirectUriVaryByTenant',
        branches: {
          YES: [SimpleValidators.arrayHasValidDynamicUrls],
          NO: [SimpleValidators.arrayHasValidUrls],
        },
      },
      complex: [ComplexValidators.validOidcVariablesUsedForRedirectUri],
    },
  },
  'oidcConfigurationJson.redirectUriAips': {
    displayName: 'Redirect URI Variables',
    section: OIDC,
    validators: {
      branch: {
        branchField: 'oidcConfigurationJson.redirectUriVaryByTenant',
        branches: {
          YES: [SimpleValidators.arrayIsNotEmpty],
        },
      },
    },
  },
  'oidcConfigurationJson.initiateLoginURI': {
    displayName: 'Initiate Login URI',
    section: OIDC,
    validators: {
      branch: {
        branchField: 'oidcConfigurationJson.initiateLoginUriRequired',
        branches: {
          YES: {
            branchField: 'oidcConfigurationJson.initiateLoginUriVaryByTenant',
            branches: {
              YES: [SimpleValidators.sizeLessThan255, SimpleValidators.isDynamicUrl],
              NO: [SimpleValidators.sizeLessThan255, SimpleValidators.isUrl],
            },
          },
        },
      },
      complex: [ComplexValidators.validOidcVariablesUsedForInitiateLoginUri],
    },
  },
  'oidcConfigurationJson.initiateLoginUriAips': {
    displayName: 'Initiate Login URI Variables',
    section: OIDC,
    validators: {
      branch: {
        branchField: 'oidcConfigurationJson.initiateLoginUriRequired',
        branches: {
          YES: {
            branchField: 'oidcConfigurationJson.initiateLoginUriVaryByTenant',
            branches: {
              YES: [SimpleValidators.arrayIsNotEmpty],
            },
          },
        },
      },
    },
  },
  'oidcConfigurationJson.postLogoutRedirectURIs': {
    displayName: 'Post Logout Redirect URI',
    section: OIDC,
    validators: {
      branch: {
        branchField: 'oidcConfigurationJson.postLogoutRedirectUriRequired',
        branches: {
          YES: {
            branchField: 'oidcConfigurationJson.postLogoutRedirectUriVaryByTenant',
            branches: {
              YES: [
                SimpleValidators.arrayHasNoEmptyStrings,
                SimpleValidators.arrayHasNoDuplicates,
                SimpleValidators.arrayHasValidDynamicUrls,
              ],
              NO: [
                SimpleValidators.arrayHasNoEmptyStrings,
                SimpleValidators.arrayHasNoDuplicates,
                SimpleValidators.arrayHasValidUrls,
              ],
            },
          },
        },
      },
      complex: [ComplexValidators.validOidcVariablesUsedForPostLogoutUri],
    },
  },
  'oidcConfigurationJson.postLogoutRedirectUriAips': {
    displayName: 'Post Logout Redirect URI Variables',
    section: OIDC,
    validators: {
      branch: {
        branchField: 'oidcConfigurationJson.postLogoutRedirectUriRequired',
        branches: {
          YES: {
            branchField: 'oidcConfigurationJson.postLogoutRedirectUriVaryByTenant',
            branches: {
              YES: [SimpleValidators.arrayIsNotEmpty],
            },
          },
        },
      },
    },
  },
  'oidcConfigurationJson.supportedOidcModes': {
    displayName: 'Select the OIDC mode supported by your application',
    section: OIDC,
    validators: {
      complex: [ComplexValidators.verifyOIDCSupportedModes],
    },
  },
  'samlConfigurationJson.otherSingleSignOnUrl.requestableUrls': {
    displayName: 'Requestable SSO URLs',
    section: SAML,
    validators: {
      simple: [SimpleValidators.validRequestableSSOUrls],
      complex: [ComplexValidators.arrayHasValidSamlVariablesUsed],
    },
  },
  'oauthConfigurationJson.configGuideLink': {
    displayName: 'Link to configuration guide',
    section: OAUTH,
    validators: {
      simple: [SimpleValidators.isUrl],
    },
  },
  'oauthConfigurationJson.scopes': {
    displayName: 'Scopes',
    section: OAUTH,
    validators: {
      simple: [SimpleValidators.arrayIsNotEmpty],
    },
  },
  'connectorConfigurationMediated.userDocumentURL': {
    displayName: 'User Documentation URL',
    section: CONNECTOR,
    validators: {
      simple: [SimpleValidators.isUrl],
    },
  },
  'connectorConfigurationMediated.apiDocumentURL': {
    displayName: 'API documentation URL',
    section: CONNECTOR,
    validators: {
      simple: [SimpleValidators.isUrl],
    },
  },
};

class AppConfigurationValidations {
  static GENERAL = GENERAL;
  static SAML = SAML;
  static SCIM = SCIM;
  static OIDC = OIDC;
  static OAUTH = OAUTH;
  static CONNECTOR = CONNECTOR;

  /**
   * Return all validations
   * @returns
   */
  static getAllValidations() {
    return validations;
  }

  /**
   * Return the validation for a given field
   * @param field
   * @returns {*}
   */
  static getValidations(field) {
    return validations[field];
  }
}

export default AppConfigurationValidations;
