import { GlobalStyles, Paper, styled } from '@mui/material';
import { Stack, Typography } from '@okta/odyssey-react-mui';
import { useTranslation } from '@okta/tools.react-i18n';
import icon from '../../../img/ico_formList.png';
import icon2x from '../../../img/ico_formList@2x.png';
import { Icon, Button, Text, Title } from './shared';
import { useUser } from '../../models/common/UserProvider';
import { navigateTo } from '../../utils/Navigate';
import { htmlToReact } from '../../utils/htmlToReact';
import { getSectionName } from '../../models/appConfiguration/Section';

const Question = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: '1.15rem',
  paddingTop: theme.spacing(2),
}));

interface LandingPageProps {
  appIntegrationId?: number;
}

export const LandingPage = ({ appIntegrationId }: LandingPageProps) => {
  const { t } = useTranslation();
  const { signedIn } = useUser();

  function onClick() {
    if (signedIn) {
      navigateTo(`/app-integration/${appIntegrationId}`);
    } else {
      navigateTo('/user/sso/isv-portal');
    }
  }

  const integrationLinks = {
    OIDC: `<a href="https://developer.okta.com/docs/concepts/oauth-openid/" target="_blank">${getSectionName(
      'OIDC',
    )}</a>`,
    SCIM: `<a href="https://developer.okta.com/docs/concepts/scim/" target="_blank">${getSectionName('SCIM')}</a>`,
    SAML: `<a href="https://developer.okta.com/docs/concepts/saml/" target="_blank">${getSectionName('SAML')}</a>`,
    OAUTH: `<a href="https://developer.okta.com/docs/guides/oin-api-service-overview/" target="_blank">${getSectionName(
      'OAUTH')}</a>`,
    CONNECTOR: `<a href="https://help.okta.com/okta_help.htm?type=wf&id=ext-connector-builder" 
    target="_blank">${getSectionName('CONNECTOR')}</a>`,
  };

  const legalLinks = {
    OKTA_INTEGRATION_AGREEMENT: `<a 
    href="https://www.okta.com/sites/default/files/2023-10/Okta-Integration-Agreement-%289.20.23%29.pdf" 
    target="_blank">
    ${t('landing.legal.answer.oktaIntegrationAgreement')}</a>`,
    OKTA_PRIVACY_POLICY: `<a href="https://www.okta.com/privacy-policy/" target="_blank">
    ${t('landing.legal.answer.oktasPrivacyPolicy')}</a>`,
  };

  return (
    <>
      <GlobalStyles styles={(theme) => ({ body: { backgroundColor: theme.palette.grey[50] } })} />
      <Paper
        variant="outlined"
        sx={(theme) => ({
          padding: 6,
          marginLeft: 'auto',
          marginRight: 'auto',
          [theme.breakpoints.up('md')]: { maxWidth: '50%' },
          [theme.breakpoints.up('lg')]: { maxWidth: '42.5%' },
          [theme.breakpoints.up('xl')]: { maxWidth: '37.5%' },
        })}
      >
        <Stack spacing={3}>
          <Icon src={icon} srcSet={`${icon2x} 2x`} />
          <Title variant="h1">{t('landing.title')}</Title>
          <Question variant="h4">{t('landing.required.question')}</Question>
          <Text>{htmlToReact(t('landing.required.answer.line1', integrationLinks))}</Text>
          <Text>{t('landing.required.answer.line2')}</Text>
          <Question variant="h4">{t('landing.work.question')}</Question>
          <Text>{t('landing.work.answer.line1')}</Text>
          <Text>{t('landing.work.answer.line2')}</Text>
          <Text>{htmlToReact(t('landing.legal.answer.line1', legalLinks))}</Text>
          <Button onClick={onClick}>{t('landing.buttons.start')}</Button>
        </Stack>
      </Paper>
    </>
  );
};
