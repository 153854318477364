/**
 * @author Yuming Cao
 */
import { momentTz } from 'okta';
export default {
  /**
   * Returns formatted date string
   * @param epochTime number of seconds since 00:00:00 UTC
   * @param timezone e.g. '-0800' for PST
   * @param time format e.g. 'MM/DD/YY HH:mm:ss ZZ' shows 01483578474000 as 01/04/17 17:07:54 -0800
   */
  getDate(epochTime, timezone, format) {
    if (!epochTime) {
      return '-';
    }
    if (!timezone) {
      timezone = 'UTC';
    }
    /* eslint @okta/okta/no-unlocalized-date-formats: 0 */
    const value =
      timezone[0] === '+' || timezone[0] === '-'
        ? momentTz(epochTime)
          .utcOffset(timezone)
          .format(format)
        : momentTz(epochTime)
          .tz(timezone)
          .format(format);

    return value;
  },

  /**
   * Standard timezone and format for display on the dashboard in the user's timezone
   * @param epochTime Number of seconds since 00:00:00 UTC
   * @returns {*} MMM D, YYYY h:mm A z
   */
  getDashboardDate(epochTime) {
    const zoneName = this.getTimeZone();
    return this.getDate(epochTime, zoneName, 'MMM D, YYYY h:mm A z');
  },

  /**
   * Guesses the timezone of the user's browser.
   * If supported, the Intl API is used to determine this
   * @returns The user's timezone
   */
  getTimeZone() {
    return momentTz.tz.guess(true);
  },
};
