import TextLabel from './TextLabel';
import { internal, View } from 'okta';
import { StatusOptions } from '../models/FilterOption';

const { MultiSearchableSelect } = internal.views.forms.inputs;
const { SearchBox } = internal.views.datalist.components;

export default View.extend({
  className: 'analyst-toolbar',
  template:
    `
    <div class="toolbar-searchbox"></div>
    <div class="toolbar-text"></div>
    <div class="toolbar-search-multiselect"></div>
    `,

  initialize() {
    this.add(new SearchBox({state: this.state}), '.toolbar-searchbox');
    this.add(new TextLabel({label: 'in'}), '.toolbar-text');
    this.add(new MultiSearchableSelect({model: this.model, name: 'searchStatuses', options: StatusOptions}),
      '.toolbar-search-multiselect');
  },
});
