/**
 * @author yuming.cao
 */

import { DataListController, Model } from 'okta';
import AppConfiguration from '../models/AppConfiguration';
import Navigation from '../utils/Navigation';
import AnalystListView from '../views/AnalystListView';

const StatusesModel = Model.extend({
  props: {
    searchStatuses: 'array',
  },
});

export default DataListController.extend({
  stateEvents: {
    'change:search': '_searchPhraseUpdated',
  },
  modelEvents: {
    'change:searchStatuses': '_searchStatusUpdated',
  },
  state: {
    status: 'ALL',
    limit: 25,
  },
  View: AnalystListView,
  Collection: AppConfiguration.Collection,
  _searchPhraseUpdated() {
    if (this.model.get('searchStatuses')?.length > 0) {
      this.state.set('status', this._getSearchStatuses());
    } else {
      this.state.set('status', 'ALL');
    }
    this.state.set('integrationType', 'ALL');
  },
  _searchStatusUpdated() {
    if (this.state.get('search')) {
      this.state.set('status', this._getSearchStatuses());
    }
  },
  _getSearchStatuses() {
    return (this.model.get('searchStatuses') || []).join(',');
  },
  initialize() {
    if (this.collection) {
      this.listenTo(this.collection, 'error', (_, resp) => {
        if (resp.status === 401 || resp.status === 403) {
          Navigation.navigateTo('/');
        }
      });
    }
    this.model = new StatusesModel({ searchStatuses:
          ['SUBMITTED', 'SUBMITTED_FEEDBACK', 'OKTA_QA_NEEDED', 'OKTA_QA_FEEDBACK', 'FINAL_REVIEW']});
  },
});
