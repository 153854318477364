/**
 * @author yuming.cao
 */
import { DataList } from 'okta';
import FilterOption from '../models/FilterOption';
import Navigation from '../utils/Navigation';
import EditSubmissionStatusDialog from './EditSubmissionStatusDialog';
import IntegrationTypeFilter from './IntegrationTypeFilter';
import ListLimitDropdownView from './ListLimitDropdownView';
import OrderByDropdownView from './OrderByDropdownView';
import AnalystToolbarView from './AnalystToolbarView';
let _stepFilterOptions = {};

// container variable for filter options

let _otherFilterOptions = {};

const updateFilterAndList = function(view) {
  const filterOptions = new FilterOption();
  // update filter label counts

  filterOptions.fetch().then(
    () => {
      _stepFilterOptions = filterOptions.get('step');
      _otherFilterOptions = filterOptions.get('other');
      view.render();
    },
    (resp) => {
      if (resp.status === 401 || resp.status === 403) {
        Navigation.navigateTo('/');
      }
    }
  );

  // update config rows using controller to fetch
  if (view.__parent__) {
    view.__parent__.fetch();
  }
};

export default DataList.extend({
  className: 'app-analyst-view',

  initialize() {
    const self = this;

    updateFilterAndList(this);
    self.collection.on('statusSaved', () => {
      updateFilterAndList(self);
    });

  },
  columns: [
    {
      label: 'App & Company',
      template:
        '\
          <span class="analyst-view-appstatus">{{statusLabel}}</span>\
          <span class="analyst-view-appname"><a href="/app-integration/{{id}}">{{appNameLabel}}</a></span>\
          <span class="analyst-view-companyname">{{app.company.name}}</span>',
    },
    {
      label: 'Date Submitted',
      field: 'submittedDate',
    },
    {
      label: 'Integration Type',
      field: 'integrationType',
    },
    {
      label: 'Step',
      field: 'stepLabel',
    },
    {
      label: 'Started Step On',
      template: '\
          <span {{#if staleState}}class="pastdue-label"{{/if}}>{{updatedDate}}</span>',
    },
    {
      label: 'Step Due On',
      template: '\
          <span {{#if pastDue}}class="pastdue-label"{{/if}}>{{dueDate}}</span>\
        ',
    },
    {
      label: 'Last Modified By',
      field: 'lastAnalyst.name',
    },
    {
      label: 'Priority',
      field: 'priorityLabel',
      initialize() {
        if (this.model.get('escalated')) {
          this.$el.addClass('cell-escalated');
        }
      },
    },
    {
      label: 'Internal Comments',
      field: 'internalComment',
      maxWidth: 150,
    },
    {
      label: 'Actions',
      title: 'Actions',
      threshold: 2,
      actions: [
        {
          icon: 'edit-16',
          enabled: true,
          visible: true,
          click() {
            const self = this;
            const dialog = new EditSubmissionStatusDialog({
              model: this.model,
              collection: this.collection,
              companyName: this.model.get('app.company.name'),
            });

            this.listenTo(dialog, 'saved', () => {
              self.collection.trigger('statusSaved');
            });
            dialog.render();
          },
        },
      ],
    },
  ],

  filters: [
    IntegrationTypeFilter,
    {
      type: 'filterset',
      label: 'STEP',
      field: 'status',
      options() {
        return _stepFilterOptions;
      },
    },
    {
      type: 'filterset',
      label: 'OTHER',
      field: 'status',
      options() {
        return _otherFilterOptions;
      },
    },
    ListLimitDropdownView,
    OrderByDropdownView,
  ],
  toolbar: [
    AnalystToolbarView,
  ],
});
