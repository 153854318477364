/* eslint no-undef: 0 */ // --> OFF
/**
 * author: Azhar Iqbal
 */
import { _, $ } from 'okta';
import AppConfigurationValidations from './AppConfigurationValidations';
import SimpleValidators from './SimpleValidators';

class AppConfigurationValidator {
  /**
   * Constructor
   * @param model
   */
  constructor(model) {
    this.model = model;
  }

  /**
   * Runs the validation rules on the field and value and returns an error string
   * if a validation fails
   * @param validationRules List of validation functions to be run
   * @param key Field name
   * @param val Field Value
   * @param isComplex Validation function is Complex. I.e. Requires a model to be passed
   * @private
   */
  _findError(validationRules, key, val, isComplex) {
    const errorRule = _.find(validationRules, (rule) => {
      const ruleResult = isComplex ? rule(this.model, key, val) : rule(val);
      return _.isString(ruleResult) || _.isArray(ruleResult);
    });
    const result = {};
    if (!_.isUndefined(errorRule)) {
      result[key] = isComplex ? errorRule(this.model, key, val) : errorRule(val);
    }
    return result;
  }

  /**
   * Find the list of validations to run for the branch
   * @param branch
   * @returns {[*]|*|[]|Array|*[]}
   * @private
   */
  _findBranchValidations(branch) {
    const branchValue = this.model.get(branch.branchField);
    if (typeof branch.branches[branchValue] !== 'undefined') {
      const branchValidations = branch.branches[branchValue];

      // If it is a function, return it as an array
      if (typeof branchValidations === 'function') {
        return [branchValidations];
      }

      // If it is an array, return the array
      if (_.isArray(branchValidations)) {
        return branchValidations;
      }

      // If it is another branch, recursively dive into it to find validation array
      if ($.isPlainObject(branchValidations) && _.isString(branchValidations.branchField)) {
        return this._findBranchValidations(branchValidations);
      }
    }
    return [];
  }

  /**
   * Runs through all of the Validations for GENERAL, SCIM, SAML, OIDC and ADMIN fields and
   * returns a map of errors
   */
  validate() {
    const errors = {};

    // Validate the integration form fields
    const enabledIntegrationTypes = this.model.get('integrationType');
    _.each(AppConfigurationValidations.getAllValidations(), (v, key) => {
      const section = v.section;
      if (
        section === AppConfigurationValidations.GENERAL ||
        (enabledIntegrationTypes && _.contains(enabledIntegrationTypes, section))
      ) {
        let result = {};
        // Run the simple validations
        if (v.validators.simple) {
          result = this._findError(v.validators.simple, key, this.model.get(key), false);
        }
        // Run the branch validations
        if (_.isEmpty(result) && v.validators.branch) {
          const branchValidationRules = this._findBranchValidations(v.validators.branch);
          result = this._findError(branchValidationRules, key, this.model.get(key), false);
        }
        // Run the complex validations
        if (_.isEmpty(result) && v.validators.complex) {
          result = this._findError(v.validators.complex, key, this.model.get(key), true);
        }
        _.extend(errors, result);
      }
    });

    // Validate admin fields
    // Validate globalId is populated when app configuration is set to published.
    if (
      this.model.get('status') === 'PUBLISHED' &&
      (_.isEmpty(this.model.get('globalId')) || !SimpleValidators.isValidUUID(this.model.get('globalId')))
    ) {
      _.extend(errors, { globalId: 'You must set the Global ID when publishing an app.' });
    }

    return errors;
  }
}

export default AppConfigurationValidator;
