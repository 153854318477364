/**
 * For extending top-level props with the scim configuration props.
 *
 * @author Eric Carroll
 */
import AppInstancePropertyModel from './AppInstancePropertyModel';
export default {
  getProps() {
    return {
      'scimConfigurationJson.version': {
        type: 'string',
        values: ['SCIM_1_1', 'SCIM_2_0'],
      },
      'scimConfigurationJson.provisioningFeature.createUsers': 'boolean',
      'scimConfigurationJson.provisioningFeature.updateUserAttrs': 'boolean',
      'scimConfigurationJson.provisioningFeature.deactivateUsers': 'boolean',
      'scimConfigurationJson.provisioningFeature.importUsers': 'boolean',
      'scimConfigurationJson.provisioningFeature.importGroups': 'boolean',
      'scimConfigurationJson.provisioningFeature.profileMaster': 'boolean',
      'scimConfigurationJson.provisioningFeature.syncPassword': 'boolean',
      'scimConfigurationJson.provisioningFeature.groupPush': 'boolean',
      'scimConfigurationJson.fixedUrl': 'string',
      'scimConfigurationJson.supportsHttpPatch': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'scimConfigurationJson.authenticationMode': {
        type: 'string',
        values: ['HEADER', 'BASIC', 'OAUTH_2'],
      },
      'scimConfigurationJson.appInstanceUrl': 'string',
      'scimConfigurationJson.userNameFollowsEmailFormat': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'scimConfigurationJson.mappingsRemoved': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'scimConfigurationJson.customPatchBatchSize': 'number',
      'scimConfigurationJson.demoVideoLink': 'string',
      'scimConfigurationJson.runscopeTestLink': 'string',
      'scimConfigurationJson.runscopeCrudTestLink': 'string',

      // auth mode: oauth2
      'scimConfigurationJson.oauth2.tokenEndpoint': 'string',
      'scimConfigurationJson.oauth2.authorizeEndpoint': 'string',
      'scimConfigurationJson.oauth2.consumerKey': 'string',
      'scimConfigurationJson.oauth2.consumerSecret': 'string',

      // auth mode: header
      'scimConfigurationJson.header.tokenFormat': {
        type: 'string',
        values: ['AUTH', 'AUTH_BEARER', 'AUTH_SSWS', 'OTHER'],
        value: 'AUTH',
      },
      'scimConfigurationJson.header.other': 'string',
      'scimConfigurationJson.doesFixedUrlVaryByTenant': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'scimConfigurationJson.appInstanceProperties': {
        type: 'array',
        minItems: 0,
        maxItems: 20,
        value: [],
        props: AppInstancePropertyModel.ScimAppInstancePropertyModel.prototype.props,
      },
      'scimConfigurationJson.responseWithinTimeLimit': {
        type: 'string',
        values: ['YES', 'NO'],
      },
    };
  },
};
