/**
 * Displays a common confirmation dialog with text posing the question, and a banner detailing
 * what will happen if the action is confirmed.
 *
 * @author Eric Carroll
 */
import { Model } from 'okta';
export default Model.extend({
  props: {
    title: 'string', // Dialog title
    text: 'string', // Dialog text, posing question
    bannerTitle: 'string', // Banner title, detailing results of action
    bannerText: 'string', // Banner text, detailing results of action
    bannerType: 'string', // Banner type, defaults to info/blue
    buttonText: 'string', // Button text - clicking this causes the action to happen
    buttonType: {
      // Button type - changes the colour of the button
      type: 'string',
      values: ['error', 'primary', 'teach', 'standard'],
      value: 'primary',
    },
    showCancelButton: {
      // Display the cancel button in the bottom bar
      type: 'boolean',
      value: false,
    },
  },
});
