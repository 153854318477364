/**
 * For extending top-level props with the SAML configuration props.
 *
 * @author Eric Carroll
 */
import AppInstancePropertyModel from './AppInstancePropertyModel';
export default {
  getProps() {
    return {
      'samlConfigurationJson.appInstanceUrl': 'string',
      'samlConfigurationJson.bigBang.isBigBang': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.bigBang.backdoorUrl': 'string',
      'samlConfigurationJson.serviceType': {
        type: 'string',
        values: ['BY_THEMSELF', 'CONTACT_US'],
      },
      'samlConfigurationJson.isOnPremise': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.otherSingleSignOnUrl.supported': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.otherSingleSignOnUrl.requestableUrls': {
        type: 'array',
        minItems: 0,
        maxItems: 100,
        value: [],
      },
      'samlConfigurationJson.samlIdentifier.type': {
        type: 'string',
        values: ['NAME_ID', 'OTHER'],
      },
      'samlConfigurationJson.samlIdentifier.otherAttribute': 'string',
      'samlConfigurationJson.configGuideLink': 'string',
      'samlConfigurationJson.supportJit': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.supportUserGroupAttribute': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.connectMultipleIdP': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.signInFlow.sp': {
        type: 'boolean',
        value: false,
      },
      'samlConfigurationJson.signInFlow.spInitiatedUrl': 'string',
      'samlConfigurationJson.signInFlow.spDescription': 'string',
      'samlConfigurationJson.signInFlow.idp': {
        type: 'boolean',
        value: false,
      },
      'samlConfigurationJson.encryptedAssertion.supported': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.encryptedAssertion.encryptionAlgorithm': {
        type: 'string',
        values: ['AES128_CBC', 'AES256_CBC'],
      },
      'samlConfigurationJson.encryptedAssertion.keyTransportAlgorithm': {
        type: 'string',
        values: ['RSA_OAEP', 'RSA_15'],
      },
      'samlConfigurationJson.encryptedAssertion.sameCert': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.encryptedAssertion.cert': 'string',

      'samlConfigurationJson.spSingleSignOut.supported': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.spSingleSignOut.sameCert': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.spSingleSignOut.cert': 'string',
      'samlConfigurationJson.spSingleSignOut.doesSloUrlVaryPerTenant': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.sloAppInstanceProperties': {
        type: 'array',
        minItems: 0,
        maxItems: 20,
        value: [],
        props: AppInstancePropertyModel.SamlAppInstancePropertyModel.prototype.props,
      },
      'samlConfigurationJson.spSingleSignOut.sloUrl': 'string',
      'samlConfigurationJson.supportRelayState': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.defaultRelayState': 'string',
      'samlConfigurationJson.demoVideoLink': 'string',
      'samlConfigurationJson.testResultLink': 'string',
      'samlConfigurationJson.forceAuthentication.supported': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.forceAuthentication.howToConfigure': 'string',
      'samlConfigurationJson.forceAuthentication.howToTest': 'string',
      'samlConfigurationJson.attributeStatements': {
        type: 'array',
        minItems: 0,
        value: [],
      },
      'samlConfigurationJson.groupAttributeStatements': {
        type: 'array',
        minItems: 0,
        value: [],
      },
      'samlConfigurationJson.doesAcsUrlVaryPerTenant': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'samlConfigurationJson.appInstanceProperties': {
        type: 'array',
        minItems: 0,
        maxItems: 20,
        value: [],
        props: AppInstancePropertyModel.SamlAppInstancePropertyModel.prototype.props,
      },
      'samlConfigurationJson.acsUrl': 'string',
      'samlConfigurationJson.audienceUri': 'string',
    };
  },
};
