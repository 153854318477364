/**
 * @author Azhar Iqbal
 */
export default {
  /**
   * Converts `string` to camelCase
   *
   * @param {string} [string=''] The string to convert.
   * @returns {string} Returns the camel cased string.
   */
  toCamelCase(str) {
    // Lower cases the string
    return (
      str
        .toLowerCase()
        // Replaces any - or _ or . characters with a space
        .replace(/[-_.]+/g, ' ')
        // Removes any non alphanumeric characters
        .replace(/[^\w\s]/g, '')
        // Uppercases the first character in each group immediately following a space
        // (delimited by spaces)
        .replace(/ (.)/g, ($1) => {
          return $1.toUpperCase();
        })
        // Removes spaces
        .replace(/ /g, '')
    );
  },
};
