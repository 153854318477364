import { MouseEvent } from 'react';
import { Link } from '@okta/odyssey-react-mui';
import Navigation from '../../utils/Navigation';

interface TopBarLinkProps {
  children: string;
  link: string;
  className: string;
}

export const TopBarLink = ({ children, link, className }: TopBarLinkProps) => {
  function isCurrentPage() {
    return Navigation.getCurrentPath() === link;
  }

  function onClick(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    if (!isCurrentPage()) {
      Navigation.navigateTo(link);
    }
  }

  return (
    <Link
      className={className}
      href={link}
      sx={
        isCurrentPage()
          ? {
            color: 'black',
            cursor: 'not-allowed',
            '&:visited': {
              color: 'black',
            },
          }
          : {
            color: 'gray',
            cursor: 'pointer',
            '&:visited': {
              color: 'gray',
            },
          }
      }
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
