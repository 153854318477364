import { styled } from '@mui/material';
import { Button } from '@okta/odyssey-react-mui';

/**
 * Odyssey doesn't have a "standard" button as courage. As most of torii's buttons are standard variants, this is needed
 * to maintain styling consistency.
 * TODO: replace with Odyssey buttons after full React migration, or use custom theme when the feature is deployed.
 * See:
 * - http://courage.oktaskylab.com/button/button-examples
 * - https://odyssey.okta.design/?path=/docs/mui-components-button--button-primary
 */
export const StandardButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,

  '&:hover, &:focus-visible': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },

  '&:focus-visible': {
    outlineColor: theme.palette.primary.dark,
  },

  '&:disabled': {
    color: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    backgroundColor: theme.palette.common.white,
  },
}));
