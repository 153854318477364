/**
 * For extending model props with the common app props
 *
 * @author Eric Carroll
 */
import SimpleValidators from '../utils/SimpleValidators';
export default {
  getProps() {
    return {
      id: 'number',
      version: 'number',
      appName: 'string',
      appIcon: 'string',
      status: 'string',
      integrationType: 'array',
      createdBy: 'number',
      createdAt: 'number',
      updatedAt: 'number',
      submittedAt: 'number',
      publishedAt: 'number',
      dueAt: 'number',
      internalComment: {
        type: 'string',
        value: 'N/A',
        validate(value) {
          return SimpleValidators.isNotEmptyString(value) || 'Enter a value for Internal Comment';
        },
      },
      isResubmission: 'boolean',
      isCustomerTestRequired: 'boolean',
      justPublished: 'boolean',
      testApp: 'boolean',
      globalId: {
        type: 'string',
        validate(value) {
          return value && SimpleValidators.isNotEmptyString(value) && SimpleValidators.isValidUUID(value);
        },
      },
      versionGlobalId: {
        type: 'string',
        validate(value) {
          return value && SimpleValidators.isNotEmptyString(value) && SimpleValidators.isValidUUID(value);
        },
      },

      // last analyst to touch it
      'lastAnalyst.id': 'number',
      'lastAnalyst.oktaUserId': 'string',
      'lastAnalyst.name': 'string',
      'lastAnalyst.email': 'string',
      'lastAnalyst.roleName': 'string',
      'lastAnalyst.status': 'string',
      'lastAnalyst.company.name': 'string',
      'lastAnalyst.company.linkedOrgUrl': 'string',
      'lastAnalyst.company.orgId': 'string',
      'lastAnalyst.editionId': 'string',

      // who last submitted it
      'submittedBy.id': 'number',
      'submittedBy.oktaUserId': 'string',
      'submittedBy.name': 'string',
      'submittedBy.email': 'string',
      'submittedBy.roleName': 'string',
      'submittedBy.status': 'string',
      'submittedBy.company.name': 'string',
      'submittedBy.company.linkedOrgUrl': 'string',
      'submittedBy.company.orgId': 'string',
      'submittedBy.editionId': 'string',

      // who last published it
      'publishedBy.id': 'number',
      'publishedBy.oktaUserId': 'string',
      'publishedBy.name': 'string',
      'publishedBy.email': 'string',
      'publishedBy.roleName': 'string',
      'publishedBy.status': 'string',
      'publishedBy.company.name': 'string',
      'publishedBy.company.linkedOrgUrl': 'string',
      'publishedBy.company.orgId': 'string',
      'publishedBy.editionId': 'string',
    };
  },
};
