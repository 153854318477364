/**
 * Displays a fixed-position banner to show a custom message view.  The banner includes a clickable (x) icon to
 * close the banner.
 *
 * To use, provide the following options:
 *  1. messageView: Create a view to display the banner message.  Keep the message reasonably short as the banner
 *     lives in a fixed height element.
 *  2. fixedPageElements: The default position for the banner is directly below the top bar.  As such, other fixed
 *     elements on the page may need to be moved down the page to make room for the banner.  Supply a list of
 *     selectors in this option and the banner will automatically reposition these other elements while the banner
 *     is open.
 *
 * Created by eric.carroll on 10/27/16.
 */
import { $, _, View, createCallout, Model } from 'okta';
import Customize from '../utils/Customize';
const BannerState = Model.extend({
  props: {
    isDisplayed: 'boolean',
    shouldDisplay: 'boolean',
  },

  derived: {
    isChanging: {
      deps: ['isDisplayed', 'shouldDisplay'],
      fn(isDisplayed, shouldDisplay) {
        return isDisplayed !== shouldDisplay;
      },
    },
  },

  /**
   * Call when a state change has been handled - sets isDisplayed to match shouldDisplay
   */
  changeCompleted() {
    this.set('isDisplayed', this.get('shouldDisplay'));
  },
});

/**
 * The state of the the banner:
 * - isDisplayed : True if the banner is currently being displayed
 * - shouldDisplay : True if the banner should be displayed
 * - isChanging (derived) : if shouldDisplay does not match isDisplayed, indicating a state change is needed
 */

export default View.extend({
  /**
   * Start the banner as open.
   * Listen for banner state changes, so we know when to move other page elements.
   */
  initialize() {
    // open the banner
    this.bannerState = new BannerState();
    this.openBanner();

    // re-render on state changes - this is how the other page elements get moved
    this.listenTo(this.bannerState, 'change:shouldDisplay', this.render);
  },

  /**
   * After rendering we check if the banner state is changing.
   * If so, we move the fixed page elements down/up to make room
   */
  postRender() {
    // if our current state doesn't match our desired state, transition
    if (this.bannerState.get('isChanging')) {
      // move the fixed elements out of the way (or back, if we are hiding)
      this.moveElements(this.bannerState.get('shouldDisplay'));

      // done!
      this.bannerState.changeCompleted();
    }
  },

  /**
   * Calculates the vertical offset of the other page element's positions.
   * If this is a change, moves the other page elements.
   * @param isShowing If false, the banner's height is considered to be 0
   */
  moveElements(isShowing) {
    const height = this.$el.height() || 0;

    // calculate a delta based on the banner's current height, and its height when we last moved elements.

    const dy = (isShowing ? height : 0) - this.bannerHeight;
    // if this results in a non-zero delta, move the elements and adjust the saved banner height
    if (dy) {
      Customize.moveElementsVertical(this.options.fixedPageElements.map(selector => $(selector)), dy);
      this.bannerHeight += dy;
    }

    if (this.options.fixedPageElements.includes('.torii-flex')) {
      const gap = isShowing ? '5rem' : '2rem';
      Customize.setGapVertically('.torii-flex', gap);
    }
  },

  /**
   * Displays a call-out within the banner's fixed-position element.
   * Displays the passed message view within the call-out.
   * Hooks the call-out's remove method so we know when the call-out closes.
   */
  openBanner() {
    // initialize height adjustment values - banner moving from closed to open
    this.bannerHeight = 0;
    this.bannerState.set({ isDisplayed: false, shouldDisplay: true });

    // create a call-out to display the message view
    const bannerView = createCallout({
      type: 'info',
      size: 'compact',
      dismissible: true,
      content: new this.options.messageView({ model: this.options.model }),
    });
    this.add(bannerView);

    // hook its remove method, so we know when it closes
    const self = this;

    bannerView.remove = _.wrap(bannerView.remove, (remove, e) => {
      // let the call-out close itself
      remove.apply(bannerView, e);

      // note that we should now adjust page element positions for a closed banner
      self.bannerState.set('shouldDisplay', false);
    });

    // detect changes to the browser window, as that can resize the banner
    $(window).on('resize', _.throttle(_.bind(this.onResize, this), 50));
  },

  /**
   * Called when the browser window changes size.
   * Recalculates the position of page elements.
   */
  onResize() {
    this.moveElements(this.bannerState.get('isDisplayed'));
  },
});
