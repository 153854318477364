/**
 * Displays a common confirmation dialog with text posing the question, and a banner detailing
 * what will happen if the action is confirmed.
 *
 * @author Eric Carroll
 */
import { _, ModalDialog, createButton, createCallout } from 'okta';
export default ModalDialog.extend({
  initialize() {
    // confirm an action callback was passed
    if (!_.isFunction(this.options.action)) {
      throw new Error('Forgot to set options.action callback');
    }

    // the banner explains what will happen if the action is confirmed
    const banner = createCallout({
      type: this.model.get('bannerType') || 'info',
      size: 'compact',
      dismissible: false,
      title: this.model.get('bannerTitle'),
      subtitle: this.model.get('bannerText'),
    });

    this.add(banner, '#torii-confirmation-dialog-contents');

    if (this.model.get('showCancelButton')) {
      const self = this;

      this.add(
        createButton({
          title: 'Cancel',
          className: 'button button-cancel',
          click() {
            self.remove();
          },
          visible: true,
        }),
        '.o-form-button-bar'
      );
    }
  },

  title() {
    return this.model.get('title');
  },

  _doAction() {
    const promise = this.options.action();

    if (promise) {
      const self = this;

      promise.then(self.remove());
    } else {
      this.remove();
    }
  },

  events: {
    'click #action': '_doAction',
  },

  template:
    '<div id="torii-confirmation-dialog-contents" class="modal-content-wrap modal-container-small">' +
    '<p id="torii-confirmation-dialog-text">{{text}}</p>' +
    '</div>' +
    '<div class="o-form-button-bar">' +
    '<input id="action" type="button" class="button button-{{buttonType}}" value="{{buttonText}}">' +
    '</div>',

  params: {
    minWidth: 550,
    maxWidth: 600,
  },
});
