/**
 * Provides protection against losing changes due to navigation.
 *
 * Views that want this protection:
 *  1. Call listenToNavigation to register for pre-navigation events - provide needSave(options) and
 *     doSave(options) callback functions.
 *  2. Your needSave(options) function sets options.needSave true if you have changes you need to save
 *     before the page unloads.
 *  3. Your doSave(options) function performs the save, if needed, and adds a promise for the save to
 *     the options.savePromises array.  Navigation will not occur until all these promises complete.
 *     NOTE: This function is called if any listener indicates a save is needed - you should check that your
 *     view actually needs to save.
 *
 * Views that want to trigger navigation call navigateTo(href, options).  This triggers the following:
 *  1. The needSave callbacks are called on all registered views.  If no view indicates a need to save, the
 *     navigation completes.
 *  2. If at least one view needs to save, the doSave callbacks are called on all registered views.  When all the saves
 *     complete, the navigation completes.
 *
 * If the browser initiates an event that would cause the page to unload (bookmark, refresh, close tab, etc):
 *  1. The needSave callbacks are called on all registered views.  If no view indicates a need to save, the
 *     page-unload completes.
 *  2. If at least one view indicates it needs a save, the browser is asked to display its confirmation dialog.
 *     This dialog is browser-specific, but usually provides two options: continue or cancel.
 *
 * Created by eric.carroll on 10/20/16.
 */
/* global Backbone */
import { $, _ } from 'okta';
const navigation = {
  /**
   * Navigates to the specified href.  May pause to allow views to save changes first.
   *
   * This triggers the following:
   *  1. The needSave callbacks are called on all registered views.  If no view indicates a need to save, the
   *     navigation completes.
   *  2. If at least one view indicates it needs a save, the doSave callbacks are called on all registered views.
   *     When all the saves complete, the navigation completes.
   *
   *  NOTE: If your navigation does not actually cause the page to unload (ex: file download), or isn't in a
   *        state that allows saves (ex: signed out and navigating to the sign-in page) - set options.silent true.
   *        This skips the save checks, and simply performs the navigation.
   *
   * @param {string} href Want to navigate here
   * @param {object} [options]
   * @param {boolean} options.silent If set we just navigate, we don't ask about pending saves.
   * @param {boolean} options.newTab If set, we open the href in a new tab instead of the existing one
   */
  navigateTo(href, options) {
    // sanity check
    if (typeof href !== 'string') {
      throw new Error('You must provide an href');
    }

    // options is optional
    options = options || {};

    if (!options.silent && this._needSave()) {
      $.when(...this._doSave()).then(() => {
        this._navigateTo(href, options.newTab);
      });
    }

    // no views need to save (in step 1): navigation happens
    else {
      this._navigateTo(href, options.newTab);
    }
  },

  getSearchParams() {
    const map = {};

    const params = this._getQueryStr()
      .substring(1)
      .split('&');

    for (let i = 0; i < params.length; i++) {
      const key = params[i].split('=')[0];
      const value = params[i].split('=')[1];

      if (!key || !value) {
        continue;
      }
      map[key] = value;
    }
    return map;
  },

  _getQueryStr() {
    return window.location.search;
  },

  getCurrentPath() {
    return window.location.pathname;
  },

  /**
   * Registers the view to listen for pre-navigation events, so it doesn't lose changes when the view unloads.
   *
   * Views that want this protection:
   *  1. Call this function and provide needSave(options) and doSave(options) callback functions.
   *  2. Your needSave(options) function sets options.needSave true if you have changes you need to save
   *     before the page unloads.
   *  3. Your doSave(options) function performs the save, if needed, and adds a promise for the save to
   *     the options.savePromises array.  Navigation will not occur until all these promises complete.
   *     NOTE: This function is called if any listener indicates a save is needed - you should check that your
   *     view actually needs to save.
   *
   *  NOTE: Your view automatically unregisters when it unloads - it does not have to unregister itself.
   *
   * @param view View the register
   * @param needSave If the view has a pending save, set options.needSave true.
   * @param doSave If the view has a pending save, perform the save and add the save promise to options.savePromises.
   */
  listenToNavigation(view, needSave, doSave) {
    // sanity check
    if (!_.isFunction(needSave) || !_.isFunction(doSave)) {
      throw new Error('you must provide both needSave and doSave functions');
    }

    view.listenTo(this, 'torii.need.save', needSave);
    view.listenTo(this, 'torii.do.save', doSave);
  },

  /**
   * Browser event for when the page is about to unload.
   * If the in-app navigation flag is not set, triggers needSave events and, if at least one view indicates it needs
   * a save, tells the browser to display its confirmation dialog.  This dialog is browser-specific but usually
   * allows the user to continue or cancel the action.
   * @returns {string} If defined, tells the browser to display a confirmation dialog and include this text.
   * @private
   */
  _beforeUnload() {
    const prompt = !this._inAppNavigation && this._needSave();

    // we'll prompt if this isn't an in-app navigation, and there are pending, pre-navigation saves

    // reset in-app navigation flag
    this._inAppNavigation = undefined;

    // to prompt we return explanation text - the browser will include it with its confirmation popup
    if (prompt) {
      return 'You have unsaved changes.  If you continue, all changes will be lost.';
    }
  },

  /**
   * Sets the in-app navigation flag, and then does the navigation
   * @param href Navigate here
   * @param newTab If set, opens href in a new tab
   * @private
   */
  _navigateTo(href, newTab) {
    this._inAppNavigation = true;
    if (newTab) {
      window.open(href);
    } else {
      window.location.href = href;
    }
  },

  /**
   * Returns true if at least one listener indicates a need to save
   * @returns {boolean} True if saved needed
   * @private
   */
  _needSave() {
    const options = { needSave: false };

    // trigger - if a view needs to perform a save it'll set this flag

    this.trigger('torii.need.save', options);

    // done!
    return options.needSave;
  },

  /**
   * Returns a list of promises for the saves performed
   * @returns {Array} List of save promises
   * @private
   */
  _doSave() {
    const options = { savePromises: [] };

    // trigger - if a view performs a save it'll add it's promise to this list

    this.trigger('torii.do.save', options);

    // done!
    return options.savePromises;
  },
};

// give Navigation event manager abilities
_.extend(navigation, Backbone.Events);

// register to be told about browser actions that would cause the current page to unload - refresh the browser,
// close the tab, click a bookmark, etc
$(window).on('beforeunload', _.bind(navigation._beforeUnload, navigation));

export default navigation;
