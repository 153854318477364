/**
 * For extending top-level props with the application configuration props.
 *
 * @author Eric Carroll
 */
export default {
  getProps() {
    return {
      // app information
      'appConfigurationJson.appInfo.existsInOan': {
        type: 'string',
        values: ['YES', 'NO'],
      },

      // exists in oan: yes
      'appConfigurationJson.appInfo.inOan.name': 'string',
      'appConfigurationJson.appInfo.updatedInfo': 'string',

      // exists in oan: no
      'appConfigurationJson.appInfo.notInOan.name': 'string',
      'appConfigurationJson.appInfo.notInOan.website': 'string',

      'appConfigurationJson.appInfo.notInOan.category': {
        type: 'string',
        values: [
          'APPS_FOR_GOOD',
          'COLLABORATION',
          'CONTENT',
          'CONSUMER',
          'CRM',
          'DATA',
          'ECOMMERCE',
          'EDUCATION',
          'ERP',
          'FINANCE',
          'HCM',
          'HEALTH',
          'MARKETING',
          'NEWS',
          'PRODUCTIVITY',
          'SALES',
          'SUPPLY',
          'SECURITY',
          'SYSTEM',
          'SOCIAL',
          'SOFTWARE',
          'TELECOMMUNICATIONS',
          'TRAVEL',
          'WEB',
        ],
        value: null,
      },
      'appConfigurationJson.appInfo.notInOan.categoryList': {
        type: 'array',
        minItems: 0,
        maxItems: 5,
        items: {
          type: 'string',
        },
      },

      'appConfigurationJson.appInfo.notInOan.description': 'string',
      'appConfigurationJson.appInfo.notInOan.icon': 'string',

      // customer support
      'appConfigurationJson.customerSupport.configGuideLink': 'string',
      'appConfigurationJson.customerSupport.publicSupportContacts': {
        type: 'array',
        minItems: 1,
        value: [
          {
            data: '',
            type: 'EMAIL',
          },
        ],
      },
      'appConfigurationJson.customerSupport.escalationSupportContact': 'string',

      // test account
      'appConfigurationJson.testAccount.url': 'string',
      'appConfigurationJson.testAccount.user': 'string',
      'appConfigurationJson.testAccount.password': 'string',
      'appConfigurationJson.testAccount.instructions': 'string',
    };
  },
};
