import { initOktaReactI18n } from '@okta/tools.react-i18n';
import englishProps from '../../properties/json/torii.json';
import pseudoLocTranslations from '../../properties/json/torii_ok_PL.json';

export function initializeI18n() {
  initOktaReactI18n({
    namespace: 'torii',
    supportedLanguages: ['en', 'ok_PL'],
    fallbackLanguage: 'en',
    translations: {
      en: englishProps,
      'ok_PL': pseudoLocTranslations,
    },
    languageDetectorOptions: {
      localeCookieName: 'okta_user_lang',
    },
  });
}
