export function initializePendo(visitorId, accountId, editionId) {
  if (window.pendo && typeof window.pendo.initialize === 'function') {
    window.pendo.initialize({
      visitor: { id: visitorId },
      account: {
        id: accountId,
        isOptOutEndUserFeedback: true,
        editionId: editionId,
      },
      excludeAllText: true,
    });
  }
}
