/**
 * @author ycao
 */
import AnalystPageController from './controllers/AnalystPageController';
import LandingPageController from './controllers/LandingPageController';
import { initializePage } from './utils/InitializePage';

initializePage({
  requiresPendo: true,
  addFooter: true,
  successCallback(currentUser) {
    if (currentUser.canManageAppSubmissions()) {
      const analystPageController = new AnalystPageController({
        el: '.torii-landing-page-content',
        currentUser: currentUser,
      });

      analystPageController.render();
    } else {
      const landingPageController = new LandingPageController({
        el: '.torii-landing-page-content',
        currentUser: currentUser,
      });

      landingPageController.render();
    }
  },
  errorCallback(response) {
    if (response.status === 401 || response.status === 403) {
      const landingPageController = new LandingPageController({
        el: '.torii-landing-page-content',
      });

      landingPageController.render();
    }
  },
});
