import { Link, LinkProps } from '@okta/odyssey-react-mui';
import { Markup, TransformCallback } from 'interweave';

const transform: TransformCallback = (node, children) => {
  // tagName is always upper case: https://developer.mozilla.org/en-US/docs/Web/API/Element/tagName
  if (node.tagName === 'A') {
    const attributes: Record<string, string> = {};
    // @ts-expect-error https://developer.mozilla.org/en-US/docs/Web/API/Element/attributes
    for (const attr of node.attributes) {
      attributes[attr.name] = attr.value;
    }

    return <Link {...(attributes as LinkProps)}>{children}</Link>;
  }

  // Use the default element
  return undefined;
};

export function htmlToReact(htmlString: string): JSX.Element {
  return <Markup content={htmlString} allowList={['a', 'ul', 'ol', 'li', 'strong']} transform={transform} noWrap/>;
}
