import { getI18n } from '@okta/tools.react-i18n';

/**
 * Represents each configuration section in the tab.
 */
type Section = 'GENERAL' | 'OIDC' | 'SCIM' | 'SAML' | 'OAUTH' | 'CONNECTOR';

export function getSectionName(section: Section, t?: (key: string) => string) {
  t ??= getI18n().t;
  return t(`sections.${section}`);
}

export type IntegrationType = Exclude<Section, 'GENERAL'>;
