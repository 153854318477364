/**
 * @author Mark Sztainbok
 */
import { _ } from 'okta';

/**
 * Checks if the user have a feature flag enabled (Based of the org level feature flag)
 * @param  {String}  feature Feature name
 * @return {Boolean}
 */
function hasFeature(feature) {
  return _.contains(window._features || [], feature);
}

export { hasFeature };
