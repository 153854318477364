/**
 * Created by eric.carroll on 8/29/16.
 */
import { Model } from 'okta';
export default Model.extend({
  urlRoot: '/api/v1/current-user',

  props: {
    id: 'number',
    oktaUserId: 'string',
    name: 'string',
    email: 'string',
    roleName: 'string',
    status: 'string',
    'company.name': 'string',
    'company.linkedOrgUrl': 'string',
    'company.orgId': 'string',
    'company.orgMigrated': 'boolean',
    editionId: 'string',
  },

  derived: {
    isDisabled: {
      deps: ['status'],
      fn(status) {
        return status === 'DISABLED';
      },
    },
    isISVPortalFull: {
      deps: ['roleName'],
      fn(roleName) {
        return roleName === 'ISVPORTAL_FULL';
      },
    },
    isISVPortalLimited: {
      deps: ['roleName'],
      fn(roleName) {
        return roleName === 'ISVPORTAL_LIMITED';
      },
    },
  },
  canManageAppSubmissions() {
    return this.get('isISVPortalLimited') || this.get('isISVPortalFull');
  },
});
