import { PropsWithChildren } from 'react';
import { UserProvider, UserProviderProps } from '../models/common/UserProvider';
import { TopBar } from './common/TopBar';
import { ToriiReactContainer } from './utils/ToriiReactContainer';

type AppProps = PropsWithChildren & UserProviderProps;

export const App = ({ children, useCourage, user }: AppProps) => (
  <ToriiReactContainer>
    <UserProvider useCourage={useCourage} user={user}>
      <TopBar />
      {children}
    </UserProvider>
  </ToriiReactContainer>
);
