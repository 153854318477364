import { _, View } from 'okta';
export default View.extend({
  className: 'data-list-limit-sidebar',
  template:
    '\
      <h3 class="data-list-sidebar-heading">PAGE SIZE</h3>\
      <div class="sidebar-dropdown-wrap clearfix">\
        <select class="sidebar-dropdown">\
          {{#each options}}\
            <option value="{{@key}}">{{this}}</option>\
          {{/each}}\
        </select>\
      <div>\
    ',
  events: {
    'change select'(event, data) {
      this.state.set('limit', data.selected);
    },
  },

  getTemplateData() {
    return {
      options: {
        25: 25,
        50: 50,
        100: 100,
        500: 500,
      },
    };
  },
  postRender() {
    const self = this;

    _.defer(() => {
      self.$('select').chosen({
        width: '100%',
        // eslint-disable-next-line camelcase
        disable_search: true,
      });
    });
  },
});
