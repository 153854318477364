import { useState, MouseEvent } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { ChevronDownIcon, Link } from '@okta/odyssey-react-mui';
import { navigateTo } from '../../utils/Navigate';
import { useTranslation } from '@okta/tools.react-i18n';

//TODO: split up the menu an MUI side drawer so that non-acv2 links are split up nicely
// https://oktainc.atlassian.net/browse/OKTA-592503
const mappings = [
  { link: '/acv2', text: 'topBar.dropdown.acv2.bootstrap' },
  { link: '/catalog-editor', text: 'topBar.dropdown.acv2.catalogEditor' },
  { link: '/catalog-editor/popular-search', text: 'topBar.dropdown.acv2.popularSearch' },
  { link: '/catalog-editor/promotion-feature', text: 'topBar.dropdown.acv2.promotion' },
  { link: '/manual-activation', text: 'topBar.dropdown.acv2.manualActivation' },
];

export const ACv2Dropdown = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Link className="acv2-link" onClick={handleClick} sx={{ color: 'gray', cursor: 'pointer' }}>
        {t('topBar.dropdown.acv2.title')} <ChevronDownIcon />
      </Link>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {mappings.map(({ link, text }, index) => (
          <MenuItem key={index} onClick={() => navigateTo(link)}>
            {t(text)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
