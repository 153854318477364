export function getCookie(key: string): string {
  return document.cookie
    .split(/;\s*/)
    .find((row) => row.startsWith(`${key}=`))
    ?.split('=')[1];
}

const CSRF_COOKIE = 'csrf';
const CSRF_HEADER = 'X-CSRF-TOKEN';

/** Sets the CSRF header for manual XHR requests, for example file uploads. */
export function setCSRFHeader(xhr: XMLHttpRequest) {
  xhr.setRequestHeader(CSRF_HEADER, getCookie(CSRF_COOKIE));
}

/** Generates a CSRF header for fetch requests. */
export function createCSRFHeader() {
  return { [CSRF_HEADER]: getCookie(CSRF_COOKIE) };
}
