import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { odysseyTheme, ThemeProvider } from '@okta/odyssey-react-mui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { FC, ReactElement } from 'react';

export const ToriiReactContainer: FC<{ children: ReactElement }> = ({ children }) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={odysseyTheme}>
        <ThemeProvider>{children}</ThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  );
};
