/**
 * @author Eric Carroll
 */
import { _, internal } from 'okta';
const { Cookie } = internal.util;

/**
 * Returns if this attributes starts with the text of one entry in the attributeFilter list
 * ex: inAttributeFilter(['samlConfigurationJson.', 'scimConfigurationJson.'], 'samlConfigurationJson.isUrlSame')
 *     returns true
 * @param attributeFilter
 * @param attribute
 * @returns {boolean|*}
 */
function inAttributeFilter(attributeFilter, attribute) {
  return attributeFilter.some((filter) => {
    // matches if the changed attribute starts with the filter - this handles flattened attributes
    return attribute.lastIndexOf(filter, 0) === 0;
  });
}

export default {
  /**
   * Returns true if the two objects are the same. false otherwise.
   * @param a
   * @param b
   * @returns {boolean}
   */
  isEqual(a, b) {
    if (a === b) {
      return true;
    }
    if (a instanceof Date && b instanceof Date) {
      return a.getTime() === b.getTime();
    }
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
      return a === b;
    }
    if (a === null || a === undefined || b === null || b === undefined) {
      return false;
    }
    if (a.prototype !== b.prototype) {
      return false;
    }
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) {
      return false;
    }
    return keys.every(k => this.isEqual(a[k], b[k]));
  },

  /**
   * Returns a hash of changed attributes
   * @param model  Model of interest
   * @param attributeFilter  If set, only these attributes are considered
   * @returns {*} hash of changed attributes, or false if no changes
   */
  changedAttributes(model, attributeFilter) {
    // sanity check
    if (!model) {
      throw new Error('model is required, and not set');
    }

    // get all the changed attributes
    const attrs = model.changedAttributes();

    if (!attrs || !attributeFilter) {
      return attrs;
    }

    // at least one attribute has changed, and there is a filter
    const filtered = {};

    Object.keys(attrs).forEach((changed) => {
      if (inAttributeFilter(attributeFilter, changed)) {
        filtered[changed] = attrs[changed];
      }
    });
    return Object.keys(filtered).length ? filtered : false;
  },

  /**
   * Returns true if the model has changed
   * @param model Model of interest
   * @param attributeFilter If set, only these attributes are considered
   * @returns {boolean} True if model has changed
   */
  hasChanged(model, attributeFilter) {
    const attrs = this.changedAttributes(model, attributeFilter);

    // get the changed attributes

    // return true if there is at least one not-ignored attribute
    return attrs ? Boolean(Object.keys(attrs).length) : false;
  },

  /**
   * Returns attributes in the two models, and returns a list of attributes who's values differ
   * @param modelOne To compare
   * @param modelTwo To compare
   * @param attributeFilter If set, only attributes that start with an entry in this list are considered
   * @returns {Array} List of attributes that differ
   */
  compareModels(modelOne, modelTwo, attributeFilter) {
    const diffs = [];
    const self = this;

    _.each(modelOne.attributes, (value, key) => {
      // not filtering, or filter matches
      if (!attributeFilter || inAttributeFilter(attributeFilter, key)) {
        // changed
        if (self.isFieldDifferent(modelOne, modelTwo, key)) {
          diffs.push(key);
        }
      }
    });
    return diffs;
  },

  /**
   * Makes a url call for a given action
   * @param model Model associated with the url call, and on which we are performing the action
   * @param type 'PUT' or 'POST'
   * @param action Appended to url, ex: 'submit' results in 'api/v1/app-configurations/11/submit'
   * @param opts Additional options for the call
   * @param captureResponse If true, the call response is used to set the model's attributes
   * @param baseUrl If set, the API url is based on this.  If not, the model's URL is used
   */
  doAction(model, type, action, opts, captureResponse, baseUrl) {
    // sanity check
    if (!model || !type || !action) {
      throw new Error('model, type, and action are required, and not all set');
    }

    // setup url and options
    const url = baseUrl || model.url();
    const options = {
      url: url + '/' + action,
      type: type,
    };

    options.beforeSend = function(xhr) {
      xhr.setRequestHeader('X-CSRF-TOKEN', Cookie.getCookie('csrf'));
    };

    // merge with passed in options
    _.extend(options, opts);

    // make the call
    const syncCall = model.sync.call(model, null, model, options);

    // capture the response
    if (captureResponse) {
      syncCall.then((resp) => {
        const serverAttrs = model.parse(resp, options);

        model.set(serverAttrs, options);
      });
    }

    // done!
    return syncCall;
  },

  /**
   * Returns true if the field value would display as empty.
   * Empty means the value is undefined, null, or an empty string.  Or the model itself is undefined or null.
   * @param model Holds the value
   * @param field The value of interest
   * @returns {boolean} True if the field value is empty
   */
  isFieldEmpty(model, field) {
    // model itself is null
    if (!model) {
      return true;
    }

    // field would display as not being set
    const value = model.get(field);

    return _.isUndefined(value) || value === null || value === '';
  },

  /**
   * Return true if the field value in the model differs from the field value in the previous model.
   * If both fields would display as empty (undefined, null, or empty string) that counts as a match.
   * @param model Holds the value
   * @param prevModel Holds the previous value
   * @param field Value of interest
   * @returns {string|boolean} True if the field values differ
   */
  isFieldDifferent(model, prevModel, field) {
    // both would display as empty
    const valueIsEmpty = this.isFieldEmpty(model, field);
    const prevIsEmpty = this.isFieldEmpty(prevModel, field);
    if (valueIsEmpty && prevIsEmpty) {
      return false;
    }

    // only one would display as empty
    if (valueIsEmpty || prevIsEmpty) {
      return true;
    }

    return !this.isEqual(model.get(field), prevModel.get(field));
  },

  /**
   * If options is defined, and the model value is in options, the display value is the options value
   * @param model Holds the values
   * @param field The value of interest
   * @param options Map from value to display value
   * @returns {*} Display value
   */
  getDisplayValue(model, field, options) {
    const value = model ? model.get(field) : undefined;

    // no options or no values - just return value

    if (_.isUndefined(options) || _.isUndefined(value)) {
      return value;
    }

    // options - look it up
    return options[value] || value;
  },

  /**
   * When an API response involves an array of objects, those objects do not automatically wrapped in models.
   * This function takes an array and a model constructor, and returns an array of populated models.
   * @param inArray To turn into an array of models
   * @param outModel Model constructor
   * @returns {Array} Array of models
   */
  arrayToModels(inArray, OutModel) {
    const outArray = [];

    // convert each entry in the input array

    (inArray || []).forEach((inEntry) => {
      const outEntry = new OutModel();

      // we let the model parse the entry - this flattens the entry

      outEntry.set(outEntry.parse(inEntry));

      // add the model to the output array
      outArray.push(outEntry);
    });

    // done!
    return outArray;
  },
};
