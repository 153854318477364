/**
 * This is a temporary derivation of the Wizard's Progress Bar, so it can be used outside the Wizard.
 *
 * There is a ticket (OKTA-110721) to refactor Courage, and remove the need for this temporary view.
 *
 * @author Eric Carroll
 */
import { _, View, internal } from 'okta';
const WizardProgressBar = internal.views.wizard.WizardProgressBar;
const WizardStateMachine = internal.views.wizard.WizardStateMachine;
const ProgressTrackerStep = WizardProgressBar.ProgressBarStep.extend({
  // adds a title to the step icon
  template: '<span class="icon icon-16 icon-only"></span>' + '{{#if title}}<p class="step-title">{{title}}</p>{{/if}}',

  /**
   * @returns Title for the step icon
   */
  getTemplateData() {
    return {
      title: this.options.title,
    };
  },

  /**
   * Wrap postRender to remove uppercase transformation
   */
  postRender() {
    WizardProgressBar.ProgressBarStep.prototype.postRender.apply(this);
    this.$el.css({ 'text-transform': 'none' });
  },
});

/**
 * Overrides the ProgressBarStep to apply our customizations
 */

const ProgressTracker = WizardProgressBar.extend({
  /**
   * Override base addStep to add our custom ProgressTrackerStep, and to pass the step title to it.
   * @param step
   */
  addStep(step) {
    if (!step.substep) {
      this.add(ProgressTrackerStep, {
        options: {
          counter: this.__counter,
          title: step.title,
        },
      });
    }
    this.__counter++;
  },
});

/**
 * Overrides the WizardProgressBar to add our derived ProgressTrackerStep,
 * rather than the wizard's ProgressBarStep
 */

/**
 * Replacement container for the ProgressBar
 */
export default View.extend({
  /**
   * Convert the steps options into the form expected by the progress bar
   */
  initialize() {
    // capture once - also used by getTemplateData
    this.steps = _.result(this.options, 'steps');
    this.step = _.result(this.options, 'step');

    // confirm both of the above are set
    if (_.isUndefined(this.steps) || !this.steps.length || _.isUndefined(this.step)) {
      throw new Error('Forgot to set steps or step');
    }

    // populate to the state machine the progress bar expects
    // add the progress tracker to our template
    this.state = new WizardStateMachine(null, { steps: this.steps });
    this.state.set('step', this.step);
    const progressTracker = new ProgressTracker({ wizardState: this.state });

    this.add(progressTracker, '.o-wizard-progressbar-wrap');
  },

  /**
   * Change the step the progress tracker is displaying
   * @param step Step to display
   */
  setStep(step) {
    this.state.set('step', step);
  },

  /**
   * The styles that we want are nested under styles that do things we don't want - like add borders and padding.
   * We remove that stuff after rendering.
   */
  postRender() {
    const $outer = this.$('div.o-wizard');

    // find the outer element - remove margin

    $outer.css({ margin: '0px auto' });

    // find the progress bar wrap - remove border and padding
    const $wrap = this.$('div.o-wizard-progressbar-wrap');

    $wrap.css({ border: 'none', padding: '0px' });

    // find the progress bar line - adjust for the removed padding
    const $line = this.$('div.o-wizard-progressbar-line');

    $line.css({ top: '16px' });
  },

  // recreates the structure/styles the progress bar styles expect.
  // the progress bar won't render unless it is nested under o-wizard and o-wizard-progressbar-wrap.
  template:
    '<div class="o-wizard o-wizard-progressbar-steps-{{stepCount}}" id="torii-progress-tracker">' +
    '<div class="o-wizard-progressbar-wrap">' +
    '<div class="o-wizard-progressbar-line"></div>' +
    '</div>' +
    '</div>',

  /**
   * @returns stepCount, used by the template to pick the class that'll set the widths
   */
  getTemplateData() {
    return {
      stepCount: Math.min(this.steps.length, 5),
    };
  },
});
