/**
 * This wrapper is mainly for testing. window.location in jsdom cannot be mocked easily, and it is easier to wrap this
 * around to test redirections.
 */
export function navigateTo(url: string) {
  location.assign(url);
}

export function reload() {
  location.reload();
}
