import { _, View } from 'okta';

const defaultOption = 'id';

export default View.extend({
  className: 'data-order-by-sidebar',
  template:
    '\
      <h3 class="data-list-sidebar-heading">ORDER BY</h3>\
      <div class="sidebar-dropdown-wrap clearfix">\
        <select class="sidebar-dropdown">\
          {{#each options}}\
            <option value="{{@key}}">{{this}}</option>\
          {{/each}}\
        </select>\
      <div>\
    ',
  events: {
    'change select'(e, data) {
      this.state.unset('search');
      this.state.set('orderBy', data.selected);
    },
  },

  getTemplateData() {
    return {
      options: {
        id: 'id',
        updatedAt: 'Started Step On Date',
        priority: 'Priority',
        escalatedPriority: 'Escalated, Priority',
      },
    };
  },

  postRender() {
    const select = this.$('select');
    select.val(this.state.get('orderBy') || defaultOption);
    _.defer(() => {
      select.chosen({
        width: '100%',
        // eslint-disable-next-line camelcase
        disable_search: true,
      });
    });
  },
});
