/**
 * @author Azhar Iqbal
 */
/* eslint complexity: [2, 7] */
import { _, Model } from 'okta';
import SimpleValidators from '../utils/SimpleValidators';
import StringUtils from '../utils/StringUtils';

const displayNames = {
  labelName: 'Label Name',
  helpText: 'Help Text',
  aipType: 'Type',
  variableName: 'Variable Name',
};

const BaseAppInstancePropertyModel = Model.extend({
  idAttribute: 'labelName',
  props: {
    labelName: 'string',
    aipType: { type: 'string', values: ['string', 'url', 'httpsUrl'] },
    helpText: 'string',
  },
  derived: {
    variableName: {
      deps: ['labelName'],
      fn(labelName) {
        if (labelName === undefined || labelName === '') {
          return '';
        }
        const prefix = this.mode === 'SAML' ? 'org' : 'app';
        return '${' + prefix + '.' + StringUtils.toCamelCase(labelName) + '}';
      },
    },
  },
  local: {
    existingVariables: {
      type: 'array',
      value: [],
    },
  },
  parse(resp) {
    delete resp.variableName;
    return resp;
  },
  toJSON() {
    const json = Model.prototype.toJSON.apply(this, arguments);
    json.variableName = this.get('variableName');
    return json;
  },
  validate() {
    const error = {};
    if (!SimpleValidators.isNotEmptyString(this.get('labelName'))) {
      error.labelName = 'Empty Label Name is not allowed';
    }

    if (!SimpleValidators.isNotEmptyString(this.get('helpText'))) {
      error.helpText = 'Empty Help Text is not allowed';
    }

    const sizeError = SimpleValidators.sizeLessThanN(50, this.get('labelName'));
    if (_.isString(sizeError)) {
      error.labelName = sizeError;
    }

    const existingVariableNames = _.pluck(this.get('existingVariables'), 'variableName');
    if (_.contains(existingVariableNames, this.get('variableName'))) {
      error.variableName = 'Variable Name must be unique within the ' + this.mode + ' section';
    }

    const existingLabelNames = _.pluck(this.get('existingVariables'), 'labelName');
    if (_.contains(existingLabelNames, this.get('labelName'))) {
      error.labelName = 'Label Name must be unique within the ' + this.mode + ' section';
    }

    return error;
  },
  getFieldDisplayName(field) {
    return displayNames[field] || field;
  },
});

const SamlAppInstancePropertyModel =
  BaseAppInstancePropertyModel.extend({
    mode: 'SAML',
  });

const OidcAppInstancePropertyModel =
  BaseAppInstancePropertyModel.extend({
    mode: 'OIDC',
  });

const ScimAppInstancePropertyModel =
  BaseAppInstancePropertyModel.extend({
    mode: 'SCIM',
  });

export default {
  SamlAppInstancePropertyModel,
  OidcAppInstancePropertyModel,
  ScimAppInstancePropertyModel,
};
