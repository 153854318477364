/**
 * @author Eric Carroll
 */
import { _ } from 'okta';
const DASHBOARD_UNDER_REVIEW = 'Pending Review';

// high-level status displayed on dashboard

const ACTION_REQUIRED = 'Action Required';
const DASHBOARD_PUBLISHED = 'Published';
const DASHBOARD_IN_PROGRESS = 'Draft';
const DASHBOARD_DEPRECATED = 'Deprecated';
const STATUS_MAP = {
  SUBMITTED: {
    dashboard: DASHBOARD_UNDER_REVIEW,
    title: 'Pending review by Okta',
    color: 'yellow',
    step: 0,
    label: 'To be reviewed by Okta',
  },
  SUBMITTED_FEEDBACK: {
    dashboard: ACTION_REQUIRED,
    title: 'Action required',
    color: 'yellow',
    step: 0,
    label: 'ISV needs to review',
  },
  OKTA_QA_NEEDED: {
    dashboard: DASHBOARD_UNDER_REVIEW,
    title: 'Pending review by Okta',
    color: 'yellow',
    step: 1,
    label: 'To be QA tested by Okta',
  },
  OKTA_QA_FEEDBACK: {
    dashboard: ACTION_REQUIRED,
    title: 'Action required',
    color: 'yellow',
    step: 1,
    label: 'ISV needs to fix bugs',
  },
  FINAL_REVIEW: {
    dashboard: DASHBOARD_UNDER_REVIEW,
    title: 'Pending review by Okta',
    color: 'yellow',
    step: 1,
    label: 'Final Review',
  },
  PUBLISHED: {
    dashboard: DASHBOARD_PUBLISHED,
    title: 'Published',
    color: 'green',
    label: 'Published',
  },
  NOT_SUBMITTED: {
    dashboard: DASHBOARD_IN_PROGRESS,
    title: 'Draft',
    color: 'blue',
    label: 'Draft - ISV new',
  },
  NOT_SUBMITTED_NEW: {
    dashboard: DASHBOARD_IN_PROGRESS,
    title: 'Draft',
    color: 'blue',
    label: 'Draft - ISV new',
  },
  NOT_SUBMITTED_FORK: {
    dashboard: DASHBOARD_IN_PROGRESS,
    title: 'Draft',
    color: 'blue',
    label: 'Draft - ISV fork',
  },
  DISCARDED: {
    dashboard: DASHBOARD_IN_PROGRESS,
    title: 'Draft',
    color: 'blue',
    label: 'Draft - ISV no response',
  },
  DEPRECATED: {
    dashboard: DASHBOARD_DEPRECATED,
    title: 'Deprecated',
    color: 'red',
    label: 'Deprecated - Submission migrated',
  },
};

// values derived from a status
//  dashboard - high-level status displayed on dashboards
//  color - color associated with status
//  step - workflow step - statuses outside of the analyst workflow won't have one
//  label - workflow label - statuses outside of the analyst workflow tend to have one for emails and the like

export default {
  getDashboardStatus(status, uppercase) {
    let dashboard = STATUS_MAP[status] ? STATUS_MAP[status].dashboard : undefined;

    if (uppercase && !_.isUndefined(dashboard)) {
      dashboard = dashboard.toUpperCase();
    }
    return dashboard;
  },

  getDashboardTitle(status) {
    return STATUS_MAP[status] ? STATUS_MAP[status].title : undefined;
  },

  getColor(status) {
    return STATUS_MAP[status] ? STATUS_MAP[status].color : undefined;
  },

  getStep(status) {
    const step = STATUS_MAP[status] ? STATUS_MAP[status].step : undefined;

    return step;
  },

  getStepLabel(status) {
    return STATUS_MAP[status] ? STATUS_MAP[status].label : undefined;
  },

  isPublished(status) {
    return this.getDashboardStatus(status) === DASHBOARD_PUBLISHED;
  },

  isUnderReview(status) {
    return (
      this.getDashboardStatus(status) === DASHBOARD_UNDER_REVIEW || this.getDashboardStatus(status) === ACTION_REQUIRED
    );
  },

  isInProgress(status) {
    return this.getDashboardStatus(status) === DASHBOARD_IN_PROGRESS;
  },

  isUnderIsvReview(status) {
    return this.getDashboardStatus(status) === ACTION_REQUIRED;
  },

  isDeprecated(status) {
    return this.getDashboardStatus(status) === DASHBOARD_DEPRECATED;
  },
};
