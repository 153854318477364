import { createRoot } from 'react-dom/client';
import { View } from 'okta';
import { ToriiReactContainer } from './ToriiReactContainer';

export default View.extend({
  render() {
    this.reactRoot = createRoot(this.el);
    this.reactRoot.render(
      <ToriiReactContainer>
        <this.options.element {...this.options.props} />
      </ToriiReactContainer>,
    );
    return this;
  },

  remove() {
    this.reactRoot.unmount(this.el);
    View.prototype.remove.call(this);
  },
});
