export default {
  getProps() {
    return {
      'oauthConfigurationJson.configGuideLink': 'string',
      'oauthConfigurationJson.scopes': {
        type: 'array',
        items: {
          type: 'string',
          value: '',
        },
        value: [],
      },
      'oauthConfigurationJson.testedInOkta': {
        type: 'boolean',
        value: false,
      },
    };
  },
};
