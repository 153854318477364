/**
 * @author Eric Carroll
 */
import { Model, Collection } from 'okta';
import ModelUtils from '../utils/ModelUtils';
import AppVersion from './AppVersion';
const App = Model.extend({
  urlRoot: '/api/v1/apps',

  props: {
    id: 'number',
    appName: 'string',
    'company.name': 'string',
    'company.linkedOrgUrl': 'string',
    'company.orgId': 'string',
    appConfigurations: 'array',
  },

  /**
   * Wrap a model around each configuration
   * @param data
   * @returns {*}
   */
  parse(data) {
    data.appConfigurations = ModelUtils.arrayToModels(data.appConfigurations, AppVersion.Model);
    return data;
  },

  derived: {
    dashboardAppName: {
      deps: ['appName'],
      fn(appName) {
        return appName || 'Unnamed App';
      },
    },
  },
});

/**
 * An app.
 */

const Apps = Collection.extend({
  url: '/api/v1/apps',
  model: App,

  /**
   * Returns true if: There is more than one app in the collection,
   * or the one app has more than one version,
   * or the one version has an integration type.
   * @returns {boolean} True if collection indicates ISV has gotten started
   */
  isStarted() {
    // started if more than one app
    if (this.length !== 1) {
      return this.length > 1;
    }

    // started if the single app has more than one version
    const app = this.at(0);
    const appConfigurations = app.get('appConfigurations') || [];

    if (appConfigurations.length !== 1) {
      return appConfigurations.length > 1;
    }

    // started if the single version has an integration type
    const appConfiguration = appConfigurations[0];
    const integrationType = appConfiguration.get('integrationType') || [];

    return integrationType.length > 0;
  },
});

/**
 * A collection of apps.
 */

export default {
  Model: App,
  Collection: Apps,
};
