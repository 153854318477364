/**
 * @author Eric Carroll
 */
import { _, FormDialog, tpl } from 'okta';
import ConfirmationDialogModel from '../models/ConfirmationDialogModel';
import Customize from '../utils/Customize';
import ConfirmationDialog from './ConfirmationDialog';
const titleText = tpl('Edit {{companyName}} - {{appName}}');
const deleteText = tpl('Are you sure you want to delete {{companyName}} - {{appName}}?');
const discardText = tpl('Are you sure you want to discard {{companyName}} - {{appName}}?');
const restoreText = tpl('Are you sure you want to restore {{companyName}} - {{appName}}?');
export default FormDialog.extend({
  /**
   * Adds the edit submission status form to the dialog.
   * Throws an error is no company name was provided to this dialog.
   */
  initialize() {
    // confirm a companyName was passed
    if (!this.options.companyName) {
      throw new Error('Forgot to set options.companyName');
    }

    // add form elements - there are active and discarded flavors
    if (this.model.get('status') !== 'DISCARDED') {
      this._initializeActive();
    } else {
      this._initializeDiscarded();
    }

    // active and discarded both display the internal comment field
    this.addInput({
      type: 'textarea',
      name: 'internalComment',
      label: 'Internal comment',
    });

    this.addInput({
      type: 'checkbox',
      name: 'testApp',
      label: 'Test app',
    });

    this.addInput({
      type: 'text',
      name: 'globalId',
      label: 'Global ID',
    });

    // Only show the Version Global Id text box when the status is Published
    this.addInput({
      type: 'text',
      name: 'versionGlobalId',
      label: 'Version Global ID',
      toggleWhen: { status: 'PUBLISHED' },
    });

    const form = this.form;

    form.__showErrors = function(model, resp, showBanner) {
      Customize.formShowErrors(
        form,
        model,
        resp,
        showBanner,
        'The app submission contains the following invalid data:'
      );
    };

    this.listenTo(this.form, 'save', this._handleSave);
  },

  _handleSave() {
    if (this.model.get('status') === 'PUBLISHED') {
      this.model
        .publishToAcv2()
        .then(() => {
          this._save();
        })
        .fail(err => {
          this.trigger('error', this.model, err);
        });
    } else {
      this._save();
    }
  },

  _save() {
    const xhr = this.model.save();
    const self = this;
    if (xhr && xhr.done) {
      xhr.done(() => {
        self.trigger('saved', self.model);
        self.remove();
      });
    }
  },

  /**
   * Initialize for an active submission
   * @private
   */
  _initializeActive() {
    // the radio for the original status gets 'Current step' as its explain
    this.originalStatus = this.model.get('status');

    const self = this;

    // place the custom delete button on the button bar if the app is in DISCARDED or NOT_SUBMITTED status
    if (_.contains(['DISCARDED', 'NOT_SUBMITTED'], this.model.get('status'))) {
      this.addButton({
        text: 'Delete',
        className: 'float-l button-error',
        action() {
          // close this dialog
          self.remove();

          // replace with the confirmation dialog
          const model = new ConfirmationDialogModel({
            title: 'Delete',
            text: deleteText({
              companyName: this.options.companyName,
              appName: this.model.get('appName') || 'Unnamed App',
            }),
            bannerText:
              'This deletes the ISV\'s submission - they will not be able to see it. The ISV will ' +
              'not get an email letting them know that their app was deleted.',
            buttonText: 'Delete',
            bannerType: 'error',
            buttonType: 'error',
            showCancelButton: true,
          });
          const dialog = new ConfirmationDialog({
            model: model,
            action() {
              self._updateStatus(self.model, self.collection, 'DELETED');
            },
          });

          dialog.render();
        },
      });
    }

    // place the custom discard button on the button bar
    this.addButton({
      text: 'Discard',
      className: 'float-l',
      action() {
        // close this dialog
        self.remove();

        // replace with the confirmation dialog
        const model = new ConfirmationDialogModel({
          title: 'Discard',
          text: discardText({ companyName: this.options.companyName, appName: this.model.get('appName') }),
          bannerText:
            'This only cancels the ISV\'s submission - they can still resubmit it later. The ISV will ' +
            'get an email letting them know that their app was discarded.',
          buttonText: 'Discard',
        });
        const dialog = new ConfirmationDialog({
          model: model,
          action() {
            self._updateStatus(self.model, self.collection, 'DISCARDED');
          },
        });

        dialog.render();
      },
    });

    this.addInput({
      type: 'select',
      name: 'priority',
      label: 'Priority',
      options: {
        FIVE: '5 -- High',
        FOUR: '4',
        THREE: '3 -- Medium',
        TWO: '2',
        ONE: '1 -- Low',
        NONE: 'None',
        DISCARD: 'Discard',
      },
    });

    this.addInput({
      type: 'checkbox',
      name: 'escalated',
      label: 'Escalate',
    });

    this.addInput({
      type: 'checkbox',
      name: 'reassignLastAnalyst',
      label: 'Reassign analyst to myself',
    });

    // add the status radio
    this.addInput({
      type: 'radio',
      name: 'status',
      label: 'Step',
      options: {
        SUBMITTED: {
          label: 'To be reviewed by Okta',
          explain: this._getExplain('SUBMITTED'),
        },
        SUBMITTED_FEEDBACK: {
          label: 'ISV needs to review',
          explain: this._getExplain('SUBMITTED_FEEDBACK'),
        },
        OKTA_QA_NEEDED: {
          label: 'To be QA tested by Okta',
          explain: this._getExplain('OKTA_QA_NEEDED'),
        },
        OKTA_QA_FEEDBACK: {
          label: 'ISV needs to fix bugs',
          explain: this._getExplain('OKTA_QA_FEEDBACK'),
        },
        FINAL_REVIEW: {
          label: 'Final Review',
          explain: this._getExplain('FINAL_REVIEW'),
        },
        PUBLISHED: {
          label: 'Published',
          explain: this._getExplain('PUBLISHED'),
        },
      },
    });
  },

  /**
   * Initialize for a discard submission
   * @private
   */
  _initializeDiscarded() {
    // place the custom restore button on the button bar

    const self = this;
    // place the custom delete button on the button bar if the app is in DISCARDED or NOT_SUBMITTED status

    this.addButton({
      text: 'Delete',
      className: 'float-l button-error',
      action() {
        // close this dialog
        self.remove();

        // replace with the confirmation dialog
        const model = new ConfirmationDialogModel({
          title: 'Delete',
          text: deleteText({
            companyName: this.options.companyName,
            appName: this.model.get('appName') || 'Unnamed App',
          }),
          bannerText:
            'This deletes the ISV\'s submission - they will not be able to see it. The ISV will ' +
            'not get an email letting them know that their app was deleted.',
          buttonText: 'Delete',
          bannerType: 'error',
          buttonType: 'error',
          showCancelButton: true,
        });
        const dialog = new ConfirmationDialog({
          model: model,
          action() {
            self._updateStatus(self.model, self.collection, 'DELETED');
          },
        });
        dialog.render();
      },
    });

    this.addButton({
      text: 'Restore',
      className: 'float-l',
      action() {
        // close this dialog
        self.remove();

        // replace with the confirmation dialog
        const model = new ConfirmationDialogModel({
          title: 'Restore',
          text: restoreText({ companyName: this.options.companyName, appName: this.model.get('appName') }),
          bannerText: 'Restoring this submission will move it to Step 1A.',
          buttonText: 'Restore',
        });
        const dialog = new ConfirmationDialog({
          model: model,
          action() {
            self._updateStatus(self.model, self.collection, 'SUBMITTED');
          },
        });

        dialog.render();
      },
    });
  },

  _updateStatus(model, collection, status) {
    model.save({ status: status }, { validate: false }).then(() => {
      collection.trigger('statusSaved');
    });
  },

  /**
   * Return explain text for the radio with the givens status
   * @param status Status associated with radio
   * @returns {string} explain text
   * @private
   */
  _getExplain(status) {
    return status === this.originalStatus ? 'Current step' : '';
  },

  /**
   * Return boolean to indicate if app is a test app
   * @returns {boolean}
   * @private
   */
  _getTestApp() {
    return this.model.get('testApp');
  },

  /**
   * Returns the global id of the app
   * @returns {string} global id
   * @private
   */
  _getGlobalId() {
    return this.model.get('globalId');
  },

  /**
   * Returns the version global id of the app
   * @returns {string} version global id
   * @private
   */
  _getVersionGlobalId() {
    return this.model.get('versionGlobalId');
  },

  /**
   * Return title for the dialog: Edit <companyName> - <appName>
   * @returns {string}
   */
  title() {
    return titleText({ companyName: this.options.companyName, appName: this.model.get('appName') });
  },

  // save the model when the save button is pushed
  autoSave: false,
  hasSavingState: true,

  // need a minimum width to avoid radio label wrap
  params: {
    close: false,
    minWidth: 550,
  },
});
