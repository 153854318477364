/**
 * @author Eric Carroll
 */
import { $, Model } from 'okta';
import AppStatus from '../utils/AppStatus';
import DateUtils from '../utils/DateUtils';
import ModelUtils from '../utils/ModelUtils';
import AppProps from './AppProps';
const props = {};

// no unique props - it all comes from the common app props

$.extend(props, AppProps.getProps());

/**
 * One version of the App.
 */
const Version = Model.extend({
  props: props,

  // let us use app_configurations APIs, like publish, and not trip on the extra properties those return
  extraProperties: true,

  derived: {
    updatedDate: {
      deps: ['updatedAt'],
      fn(updatedAt) {
        return DateUtils.getDashboardDate(updatedAt);
      },
    },
    dueDate: {
      deps: ['dueAt'],
      fn(dueAt) {
        return DateUtils.getDashboardDate(dueAt);
      },
    },
    publishedDate: {
      deps: ['publishedAt'],
      fn(publishedAt) {
        return DateUtils.getDashboardDate(publishedAt);
      },
    },
    submittedDate: {
      deps: ['submittedAt'],
      fn(submittedAt) {
        return DateUtils.getDashboardDate(submittedAt);
      },
    },
    dashboardLabelUpper: {
      deps: ['status'],
      fn(status) {
        return AppStatus.getDashboardStatus(status, true);
      },
    },
    dashboardTitle: {
      deps: ['status'],
      fn(status) {
        return AppStatus.getDashboardTitle(status);
      },
    },
    dashboardColor: {
      deps: ['status'],
      fn(status) {
        return AppStatus.getColor(status);
      },
    },
    dashboardStep: {
      deps: ['status'],
      fn(status) {
        return AppStatus.getStep(status);
      },
    },
    showPublished: {
      deps: ['status'],
      fn(status) {
        return AppStatus.isPublished(status);
      },
    },
    showUnderReview: {
      deps: ['status'],
      fn(status) {
        return AppStatus.isUnderReview(status);
      },
    },
    showInProgress: {
      deps: ['status'],
      fn(status) {
        return AppStatus.isInProgress(status);
      },
    },
  },

  /**
   * Publish the submission
   * @param opts Options passed to the publish action
   * @returns {*} Promise to do the publish
   */
  publish(opts) {
    // double-check this is legal
    if (this.get('status') !== 'SIGN_OFF_NEEDED') {
      throw new Error('Cannot publish a form that does not need sign off');
    }

    // the API belongs to app configurations, not our model - we need to pass the base URL to doAction
    return ModelUtils.doAction(this, 'PUT', 'publish', opts, true, '/api/v1/app-configurations/' + this.get('id'));
  },

  /**
   * Edit a published submission
   * @param opts Options passed to the edit-published action
   * @returns {*} Promise to do the edit-publish
   */
  editPublished(opts) {
    // double-check this is legal - could still fail if another edit exists
    if (this.get('status') !== 'PUBLISHED') {
      throw new Error('Cannot edit this form, it is not published');
    }

    // the API belongs to app configurations, not our model - we need to pass the base URL to doAction
    // the API returns a new version - don't override our model with those values
    return ModelUtils.doAction(
      this,
      'POST',
      'edit_published',
      opts,
      false,
      '/api/v1/app-configurations/' + this.get('id')
    );
  },

  /**
   * Set justPublished to false
   * @returns {*} Promise to do the publish
   */
  resetJustPublished() {
    if (this.get('status') !== 'PUBLISHED') {
      throw new Error('Cannot update publish status of a form that is not published');
    }

    return ModelUtils.doAction(
      this,
      'PUT',
      'reset-just-published',
      { silent: true },
      true,
      '/api/v1/app-configurations/' + this.get('id')
    );
  },

  /**
   * Triggers request to delete the app
   * @returns {*} Promise to do the publish
   */
  deleteApp() {
    return ModelUtils.doAction(
      this,
      'PUT',
      'delete',
      { silent: false },
      true,
      '/api/v1/app-configurations/' + this.get('id')
    );
  },
});
export default {
  Model: Version,
};
