import { $, internal } from 'okta';
import CurrentUser from '../models/CurrentUser';
import { initializePendo } from './Pendo';
import { createRoot } from 'react-dom/client';
import { App } from '../views/App';
import { ToriiFooter } from '../views/common/ToriiFooter';
import { initializeI18n } from './i18n';

const { Cookie } = internal.util;

/**
 * @param {Object} options Options for initializePage
 * @param {boolean} options.requiresPendo whether pendo needs to be addeded
 * @param {boolean} options.addFooter whether a footer will be added. The footer cannot be integrated in `App` because
 * the footer has to be the last component in a flex container in order to function.
 * @param {JSX.Element} [options.children] children supplied to ReactContainer
 * @param {function} options.successCallback function to initialize controller after successful user fetch
 * @param {function} [options.errorCallback] function to initialize controller after unsuccessful user fetch.
 * This is usually not required because the browser will redirect to sign in page in most cases.
 */
export function initializePage({ requiresPendo, addFooter, children, successCallback, errorCallback }) {
  initializeI18n();

  $.ajaxSetup({
    beforeSend(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', Cookie.getCookie('csrf'));
    },
  });

  const currentUser = new CurrentUser();

  currentUser.fetch({
    success() {
      successCallback(currentUser);

      const footerType = currentUser.canManageAppSubmissions() ? 'Analyst' : 'ISV';
      createRoot(document.getElementById('app')).render(
        <App useCourage={true} user={currentUser.toJSON()}>
          {children}
          {addFooter && <ToriiFooter footerType={footerType} />}
        </App>,
      );

      if (requiresPendo && currentUser.get('oktaUserId') && currentUser.get('company.orgId')) {
        initializePendo(currentUser.get('oktaUserId'), currentUser.get('company.orgId'), currentUser.get('editionId'));
      }
    },

    error(_, response) {
      createRoot(document.getElementById('app')).render(
        <App useCourage={true}>
          {children}
          {addFooter && <ToriiFooter footerType="ISV" />}
        </App>,
      );
      errorCallback && errorCallback(response);
    },
  });
}
