/**
 * Created by eric.carroll on 10/24/16.
 */
import { $, _, createCallout, internal, tpl } from 'okta';

const { ErrorParser } = internal.views.forms.helpers;
const bulletTemplate = tpl('{{key}} - {{{errorMessage}}}');
let scrollbarWidth = 0;

export default {
  /**
   * Customize the tab list (above the border) and tab panel (below the border) to give the tabs a fixed position.
   * Also:
   *  Ups the list's z-index so the panel scrolls behind it
   *  Pads the panel so it is positioned below the list
   *  Creates a new div, positioned behind the list and matching the panel's width - to block scrolling panel content
   * @param backOptions Customize the new, block-scrolling div
   */
  fixedTabBar(backOptions) {
    // fix the tab list (this is the list of tabs above the border line)
    const $tabList = this._fixedTabList();
    // fix the tab panel (this is where the views go, below the border line)
    this._fixedTabPanel($tabList);
    // add a back-panel behind the tab list, to block out scrolling contents
    this._fixedTabListBack($tabList, backOptions);
  },

  /**
   * Give the tab list a fixed position and higher z-index.
   * @returns {*|jQuery|HTMLElement} the tab list
   * @private
   */
  _fixedTabList() {
    const $tabList = $('ul[role="tablist"]');
    $tabList.css('position', 'fixed');
    $tabList.css('z-index', 4);
    return $tabList;
  },

  /**
   * Pad the tab panel so it is positioned below the tab list
   * @param $tabList The tab list
   * @returns {*|jQuery|HTMLElement} the tab panel
   * @private
   */
  _fixedTabPanel($tabList) {
    const $tabPanel = $('div[role="tabpanel"]');
    $tabPanel.css('padding-top', $tabList.outerHeight(true));
    return $tabPanel;
  },

  /**
   * Create a new div element positioned behind the tab list
   * @param $tabList The tab list
   * @param options Customize the back - defaults to width 100%
   * @returns {*|jQuery|HTMLElement} The newly created div element
   * @private
   */
  _fixedTabListBack($tabList, options) {
    const offset = $tabList.offset();

    // get the document position of the tab list

    const css = {
      position: 'fixed',
      background: 'white',
      top: offset.top,
      left: offset.left,
      height: $tabList.outerHeight(false),
      'z-index': 3,
    };

    // default css
    const $tabListBack = $('<div>', {
      id: 'torii-tab-list-back',
      css: _.extend(css, options || { width: '100%' }),
    });

    // position at same location as the tab list
    // add it to the document, as a sibling of the tab list
    $tabList.parent().append($tabListBack);

    // done!
    return $tabListBack;
  },

  /**
   * Move a list of elements vertically
   * @param elements List of elements to move vertically
   * @param dy Pixels to move down
   */
  moveElementsVertical(elements, dy) {
    elements = elements || [];

    // we'll move the fixed elements down, based on the height of the new element
    elements.forEach(($element) => {
      this.moveElementVertical($element, dy);
    });
  },

  /**
   * Change the position of an element vertically
   * @param $element The element to move
   * @param dy Pixels to move down
   */
  moveElementVertical($element, dy) {
    // sanity check
    if (_.isUndefined($element)) {
      return;
    }

    // find its original position
    const position = $element.position();

    if (!_.isUndefined(position)) {
      const css = {};

      if (dy) {
        css.top = position.top + dy;
      }

      $element.css(css);
    }
  },

  addScrollbarWidth(elements) {
    scrollbarWidth = this._getScrollbarWidth();
    this.moveElementsHorizontal(elements, scrollbarWidth);
  },

  removeScrollbarWidth(elements) {
    this.moveElementsHorizontal(elements, -scrollbarWidth);
  },

  /**
   * Move a list of elements horizontally
   * @param elements List of elements to move horizontally
   * @param dx Pixels to move right
   */
  moveElementsHorizontal(elements, dx) {
    const mappedElements = elements.map(selector => $(selector));

    // we'll move the fixed elements right, based on the current right position of the element
    mappedElements.forEach(($element) => {
      this.moveElementHorizontal($element, dx);
    });
  },

  /**
   * Change the position of an element horizontally
   * @param $element The element to move
   * @param dx Pixels to move right
   */
  moveElementHorizontal($element, dx) {
    // sanity check
    if (_.isUndefined($element)) {
      return;
    }

    const currRight = parseInt($element.css('right'));

    if (!_.isUndefined(currRight)) {
      const css = {};

      // only adjust what we need to adjust
      if (dx) {
        css.right = currRight + dx;
      }

      $element.css(css);
    }
  },

  /**
   * Appends the given element to the body at a fixed position.
   * @param $element To be appended.
   * @param position Optional object with positional values - top, right, left, etc
   * @param zIndex Defaults to 5
   */
  fixElementToBody($element, position, zIndex) {
    // element required
    if (!$element) {
      throw new Error('$element is required, and not set');
    }

    // default css
    const css = {
      position: 'fixed',
      'z-index': zIndex || 5,
    };

    // attach it to the body
    $('body').append($element);

    // fix it at a specific position
    $element.css(_.extend(css, position || { top: '0px', left: '0px' }));
  },

  /**
   * add a class to the body tag.
   * Done here because the body tag is in the mustache file, which gets shared among views
   * @param bodyClass Class to add to body tag
   */
  addBodyClass(bodyClass) {
    $('body').addClass(bodyClass);
  },

  /**
   * TODO - REMOVE
   * Temporary patch for a Courage issue: OKTA-108823 - Form displays an empty error banner
   * when a save response includes field errors.  This patch properly populates the error banner.
   *
   * To use, add this to your form:
   *   __showErrors(model, resp, showBanner) {
   *     Customize.formShowErrors(this, model, resp, showBanner);
   *   }
   *
   * When that issue is resolved, this method can be removed - and remember to remove the
   * ErrorParser and ErrorBanner dependencies.
   */
  formShowErrors(form, model, resp, showBanner, title) {
    form.trigger('error', model);

    /* eslint max-statements: 0  complexity: 0*/
    if (form.getAttribute('showErrors')) {
      const bullets = [];
      const validationErrors = ErrorParser.parseFieldErrors(resp);

      // trigger events for field validation errors

      if (_.size(validationErrors)) {
        _.each(
          validationErrors,
          (errors, field) => {
            form.model.trigger('form:field-error', form.__errorFields[field] || field, errors);

            // Display all the error messages
            if (_.isArray(errors[0])) {
              errors[0].forEach((val) => {
                bullets.push(bulletTemplate({ key: model.getFieldDisplayName(field), errorMessage: val }));
              });
            } else {
              bullets.push(bulletTemplate({ key: model.getFieldDisplayName(field), errorMessage: errors[0] }));
            }
          },
          form,
        );
      }

      // OKTA-108823 : Allow banner to populate if there are field errors
      // else {
      let responseJSON = ErrorParser.getResponseJSON(resp);

      responseJSON = form.parseErrorMessage(responseJSON);
      const errorSummary =
        (responseJSON && (responseJSON.errorSummary || responseJSON.message)) ||
        title ||
        'Please review the form to correct the following errors:';
      // }

      // show the error message
      if (showBanner) {
        form.$('.o-form-error-container').addClass('o-form-has-errors');
        const callout = createCallout({
          type: 'error',
          title: errorSummary,
          bullets: bullets,
        });

        callout.listenTo(model, 'form:clear-errors', callout.remove);
        form.add(callout, '.o-form-error-container');
      }

      // slide to and focus on the error message
      if (form.getAttribute('scrollOnError')) {
        const $el = $('#' + form.id + ' .o-form-error-container');

        $el.length && $('html, body').animate({ scrollTop: $el.offset().top }, 400);
      }

      form.model.trigger('form:resize');
    }
  },

  /**
   * Set the text contained by the text.
   * Optionally toggle class on tag
   * @param selector To select the tag that contains the text
   * @param text Test to set
   * @param tagClass If set, this class is toggled on the tag
   * @param tagClassState If set the class is added, otherwise the class is removed
   */
  setText(selector, text, tagClass, tagClassState) {
    const $tag = $(selector);

    if ($tag) {
      $tag.text(text);
      if (tagClass) {
        $tag.toggleClass(tagClass, tagClassState);
      }
    }
  },

  disableRadio($el, radioValue) {
    const $radio = $el.find(':radio[value=' + radioValue + ']');

    // disable the radio

    $radio.attr('disabled', true);

    // assign the not-available class to the label
    const $label = $radio.next();

    $label.addClass('not-available');
  },

  _getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
  },

  setGapVertically(selector, gap) {
    const $el = $(selector);
    // sanity check
    if (_.isUndefined($el)) {
      return;
    }

    const css = {};

    css.gap = gap;

    $el.css(css);
  },
};
