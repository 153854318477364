import { Alert, CircularProgress, Stack, Typography } from '@okta/odyssey-react-mui';
import { useTranslation } from '@okta/tools.react-i18n';
import { useMutation } from '@tanstack/react-query';
import icon from '../../../img/ico_noAccess.png';
import icon2x from '../../../img/ico_noAccess@2x.png';
import { useUser } from '../../models/common/UserProvider';
import { createCSRFHeader } from '../../utils/Cookies';
import { htmlToReact } from '../../utils/htmlToReact';
import { getDisplayHostname } from '../../utils/URIUtils';
import { Button, Icon, Text, Title } from './shared';

const supportLink = '<a href="mailto:developers@okta.com">developers@okta.com</a>';

const LoadingView = () => {
  const { t } = useTranslation();
  return (
    <Text>
      <CircularProgress sx={{ marginRight: 2 }} />
      {t('landing.error.request.sending')}
    </Text>
  );
};

// eslint-disable-next-line complexity
export const LandingPageError = () => {
  const { t } = useTranslation();
  const { signedIn, user } = useUser();
  const {
    isSuccess,
    isError,
    isLoading,
    mutate: sendEmail,
  } = useMutation({
    mutationFn: async () => {
      const resp = await fetch('/api/v1/email/request-access', { method: 'POST', headers: createCSRFHeader() });
      if (!resp.ok) {
        throw new Error(resp.statusText);
      }
      return resp;
    },
  });

  if (!signedIn) {
    return;
  }
  const isDisabled = user.status === 'DISABLED';
  const companyName = user.company.name;
  const companyLinkedOrgUrl = getDisplayHostname(user.company.linkedOrgUrl);

  return (
    <Stack
      spacing={3}
      sx={(theme) => ({
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('md')]: { maxWidth: '92.5%' },
        [theme.breakpoints.up('lg')]: { maxWidth: '87.5%' },
        [theme.breakpoints.up('xl')]: { maxWidth: '80%' },
        paddingTop: '8rem',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      })}
    >
      <Icon src={icon} srcSet={`${icon2x} 2x`} />
      <Title variant="h1">
        {isDisabled
          ? t('landing.error.title.different', { companyName })
          : t('landing.error.title.access', { companyName })}
      </Title>
      <Text>
        {isDisabled
          ? htmlToReact(t('landing.error.description.different', { companyName, companyLinkedOrgUrl }))
          : htmlToReact(t('landing.error.description.access', { companyName, companyLinkedOrgUrl }))}
      </Text>
      <Text>{htmlToReact(t('landing.error.request', { companyLinkedOrgUrl, email: user.email }))}</Text>
      {!isSuccess && !isLoading && <Button onClick={() => sendEmail()}>{t('landing.error.request.button')}</Button>}
      {isLoading && <LoadingView />}
      {isSuccess && <Text>{htmlToReact(t('landing.error.request.success', { companyLinkedOrgUrl }))}</Text>}
      {isError && <Alert severity="error">{t('landing.error.request.error')}</Alert>}
      <Typography sx={{ fontSize: '0.85rem' }}>{htmlToReact(t('landing.error.contact', { supportLink }))}</Typography>
    </Stack>
  );
};
