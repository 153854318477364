import { AppBar, styled, Toolbar } from '@mui/material';
import { Box, Link } from '@okta/odyssey-react-mui';
import { useUser } from '../../models/common/UserProvider';
import { navigateTo } from '../../utils/Navigate';
import { StandardButton } from './StandardButton';
import { TopBarLink } from './TopBarLink';
import { AccountDropdown } from './AccountDropdown';
import { ACv2Dropdown } from './ACv2Dropdown';
import logo from '../../../img/okta-logo.svg';
import { useTranslation } from '@okta/tools.react-i18n';

const Logo = styled('img')({ height: '4rem', verticalAlign: 'middle' });

const ISVLinks = () => {
  const { t } = useTranslation();
  return (
    <TopBarLink className="my-app-integrations" link="/">
      {t('topBar.links.integrations.isv')}
    </TopBarLink>
  );
};

const AnalystLinks = () => {
  const { t } = useTranslation();
  return (
    <>
      <ACv2Dropdown />
      <TopBarLink className="my-app-integrations" link="/">
        {t('topBar.links.integrations.analyst')}
      </TopBarLink>
    </>
  );
};

export const TopBar = () => {
  const { t } = useTranslation();
  const { signedIn, user, canManageAppSubmissions } = useUser();
  return (
    <AppBar
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      })}
    >
      <Toolbar sx={{ columnGap: 3 }}>
        <Box sx={{ flexGrow: 1, display: 'flex', columnGap: 5, alignItems: 'center' }}>
          <Link href="/">
            <Logo id="okta-logo" src={logo} />
          </Link>
          {canManageAppSubmissions && (
            <StandardButton onClick={() => navigateTo('/api/v1/app-configurations/csv')}>
              {t('topBar.buttons.csv')}
            </StandardButton>
          )}
        </Box>

        {signedIn && (canManageAppSubmissions ? <AnalystLinks /> : <ISVLinks />)}

        {signedIn ? (
          <AccountDropdown name={user.name} />
        ) : (
          <StandardButton onClick={() => navigateTo('/user/sso/isv-portal')}>
            {t('topBar.buttons.signIn')}
          </StandardButton>
        )}
      </Toolbar>
    </AppBar>
  );
};
