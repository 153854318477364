/**
 * @author yuming.cao
 */
import { _, Model } from 'okta';

export const StatusOptions = {
  // STEP
  SUBMITTED: 'To be reviewed by Okta',
  SUBMITTED_FEEDBACK: 'ISV needs to review',
  OKTA_QA_NEEDED: 'To be QA tested by Okta',
  OKTA_QA_FEEDBACK: 'ISV needs to fix bugs',
  FINAL_REVIEW: 'Final Review',
  // OTHER
  PUBLISHED: 'Published',
  NOT_SUBMITTED: 'ISV editing',
  DISCARDED: 'Okta discarded',
  DEPRECATED: 'Deprecated - Submission migrated',
};

export default Model.extend({
  urlRoot: '/api/v1/app-configurations/count',

  props: {
    SUBMITTED: 'number',
    SUBMITTED_FEEDBACK: 'number',
    OKTA_QA_NEEDED: 'number',
    OKTA_QA_FEEDBACK: 'number',
    FINAL_REVIEW: 'number',
    PUBLISHED: 'number',
    NOT_SUBMITTED: 'number',
    DISCARDED: 'number',
    DEPRECATED: 'number',
  },
  derived: {
    step: {
      deps: ['SUBMITTED', 'SUBMITTED_FEEDBACK', 'OKTA_QA_NEEDED', 'OKTA_QA_FEEDBACK', 'FINAL_REVIEW', 'DEPRECATED'],
      fn(submitted, submittedFeedback, oktaQaNeeded, oktaQaFeedback, finalReview) {
        return {
          ALL: [
            'ALL',
            'All',
            _.reduce(
              arguments,
              (memo, num) => {
                if (num !== undefined) {
                  return memo + num;
                } else {
                  return memo;
                }
              },
              0
            ),
          ],
          SUBMITTED: ['SUBMITTED', StatusOptions.SUBMITTED, submitted],
          SUBMITTED_FEEDBACK: ['SUBMITTED_FEEDBACK', StatusOptions.SUBMITTED_FEEDBACK, submittedFeedback],
          OKTA_QA_NEEDED: ['OKTA_QA_NEEDED', StatusOptions.OKTA_QA_NEEDED, oktaQaNeeded],
          OKTA_QA_FEEDBACK: ['OKTA_QA_FEEDBACK', StatusOptions.OKTA_QA_FEEDBACK, oktaQaFeedback],
          FINAL_REVIEW: ['FINAL_REVIEW', StatusOptions.FINAL_REVIEW, finalReview],
        };
      },
    },

    other: {
      deps: ['PUBLISHED', 'NOT_SUBMITTED', 'DISCARDED', 'DEPRECATED'],
      fn(published, notSubmitted, discarded, deprecated) {
        return {
          PUBLISHED: ['PUBLISHED', StatusOptions.PUBLISHED, published],
          NOT_SUBMITTED: ['NOT_SUBMITTED', StatusOptions.NOT_SUBMITTED, notSubmitted],
          DISCARDED: ['DISCARDED', StatusOptions.DISCARDED, discarded],
          DEPRECATED: ['DEPRECATED', StatusOptions.DEPRECATED, deprecated],
        };
      },
    },
  },
});
