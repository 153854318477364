/**
 * @author Eric Carroll
 */
import { _, $, View, createCallout } from 'okta';
import Customize from '../utils/Customize';
import { hasFeature } from '../utils/Features';
import Navigation from '../utils/Navigation';
import DeleteAppButtonView from './DeleteAppButtonView';
import ProgressTrackerView from './ProgressTrackerView';
import ReactWrapper from './utils/ReactWrapper';
import { SelfServiceLander } from './landingPage/SelfServiceLander';
import { getSectionName } from '../models/appConfiguration/Section';

const INITIAL_REVIEW = 'Initial Review';
const OKTA_QA = 'Code Review';
const CUSTOMER_TEST = 'Customer Test';
const READY_TO_PUBLISH = 'Published';

/**
 * A green or red dot, followed by an integration type, followed by 'complete' or 'not complete'
 * options:
 *  types: Types supported by this configuration
 *  type: Type we want to display
 */
const IntegrationTypeView = View.extend({
  tagName: 'div',
  className: 'torii-status-container',
  template:
    '<div class="torii-status-dot {{dotColor}}"></div>' +
    '<div class="torii-status-dot-text">{{type}} {{#if completed}}on{{else}}off{{/if}}</div>',

  getTemplateData() {
    const completed = _.contains(this.options.types, this.options.type);

    return {
      dotColor: completed ? 'green' : 'red',
      type: getSectionName(this.options.type),
      completed: completed,
    };
  },
});

/**
 * A single version
 */
const VersionView = View.extend({
  tagName: 'li',
  template:
    '<div id="version-{{id}}" class="torii-app-dashboard-version ' +
    '{{#if noFooter}}torii-app-dashboard-version-bottom{{/if}}">' +
    '<div class="version-row bottom-border">' +
    '<div class="app-logo"><img src="{{appIcon}}"></div>' +
    '<div class="values">' +
    '<div class="version-section"><h4 class="version">Version {{version}}</h4>' +
    '<span class="torii-status-sticker {{dashboardStatusColor}}">{{dashboardStatusLabel}}</span>' +
    '<span class="modified integration-section">' +
    '{{#if showPublished}}Published {{publishedDate}}' +
    '{{else}}Last modified {{updatedDate}}{{/if}}' +
    '</span>' +
    '{{#if showPublished}}<span id="integration-type" class="modified">{{/if}}' +
    '</div>' +
    '<div class="button-section">' +
    '<div class="float-button-right torii-delete-app"></div>' +
    '<div class="float-button-right"><input id="view-version" type="button" ' +
    'class="dashboard-button button button-primary" value="{{#if isDraft}}Edit{{else}}View{{/if}}"></div>' +
    '{{#if showPublished}}' +
    '<div class="float-button-right"><input id="update-app" type="button" ' +
    'class="dashboard-button button torii-button-dashboard {{#if updateAppDisabled}}btn-disabled{{/if}}"' +
    '{{#if updateAppDisabled}}disabled{{/if}} value="Update"></div>' +
    '{{/if}}' +
    '</div>' +
    '</div>' +
    '</div>' +
    '<div class="version-row">' +
    '<div class="progress-title">' +
    '<h2 class="section">{{dashboardTitle}}</h2>' +
    '</div>' +
    '</div>' +
    '<div class="version-row">' +
    '{{#if showUnderReview}}<div class="progress-tracker"></div>{{/if}}' +
    '</div><div class="version-row">' +
    '<div class="progress-text">' +
    // SUBMITTED
    '{{#if isSubmitted}}<p class="section">Your submission is in Okta\'s queue for initial review.</p>' +
    '<p class="section">Okta will notify you by email when the submission review is complete.</p>' +
    '{{/if}}' +
    // SUBMITTED FEEDBACK
    '{{#if isSubmittedFeedback}}<p class="section">Okta reviewed your submission and found errors that ' +
    'require your correction.</p>' +
    '<p class="section">' +
    'Check your email for results from our submission review.</p>' +
    '{{/if}}' +
    // OKTA QA NEEDED
    '{{#if isOktaQANeeded}}' +
    '<p class="section">Your submission is in Okta\'s queue for QA testing</p>' +
    '<p class="section">Okta will notify you by email when the QA test is complete.</p>' +
    '{{/if}}' +
    // FINAL REVIEW
    '{{#if isFinalReview}}' +
    '<p class="section">Your submission is in Okta\'s queue for Final QA Review</p>' +
    '<p class="section">Okta will notify you by email when the QA test is complete.</p>' +
    '{{/if}}' +
    // OKTA QA FEEDBACK
    '{{#if isOktaQAFeedback}}' +
    '<p class="section">Okta QA tested your submission and it failed one or more acceptance criteria.</p>' +
    '<p class="section">Check your email for results from our QA test.</p>' +
    '{{/if}}' +
    // NEW DRAFT
    '{{#if isNewDraft}}' +
    '<p class="section">You are working on a draft of a new catalog integration.</p>' +
    '<p class="section">Finish preparing your draft and click Submit.</p>' +
    '{{/if}}' +
    // NEW FORK
    '{{#if isForkedDraft}}' +
    '<p class="section">You are working on a fork of a published catalog integration.</p>' +
    '<p class="section">Finish preparing your updates and click Submit.</p>' +
    '{{/if}}' +
    // DISCARDED
    '{{#if isDiscarded}}' +
    '<p class="section">Your submission was moved back to draft status due to non-response.</p>' +
    '<p class="section">Check your email for results from our previous review, resolve the issues, ' +
    'and click Submit.</p>' +
    '{{/if}}' +
    // DEPRECATED
    '{{#if isDeprecated}}<p class="section">Your submission was migrated to the OIN Wizard and deprecated' +
    ' from the OIN Manager.</p> <p class="section">Edits to the General Settings or SSO tabs won\'t be reviewed.</p>' +
    '<p class="section">View your submission in the <a href={{oinWizardUrl}}>OIN Wizard</a>' +
    ' from the Admin Console.</p>' +
    '{{/if}}' +
    '</div></div>' +
    '{{#if showFootBanner}}' +
    '<div class="app-dashboard-version-foot-banner torii-app-dashboard-version-bottom">' +
    'Once this update is {{#if showUnderReview}}published{{else}}submitted and published{{/if}}, ' +
    'it will replace Version {{prevVersion}}</div>' +
    '{{/if}}' +
    '{{#if justPublished}}<div class="torii-just-published-banner torii-app-dashboard-version-bottom">' +
    '{{#if isFirstVersion}}' +
    'Your submission is live on ' +
    '<a href="https://www.okta.com/integrations/">Okta Integration Network</a> catalog.' +
    '{{else}}' +
    'Your update was published!' +
    '{{/if}}</div>' +
    '<p class="section just-publish">To change a published catalog entry, click Update to create a fork, ' +
    'and then resubmit for review by Okta.</p>' +
    '{{/if}}' +
    '</div>',

  getTemplateData() {
    const status = this.model.get('status');
    const currentUser = this.options.user;
    const showFootBanner = !this.model.get('showPublished') && this.model.get('version') !== 1;
    const justPublished = this.model.get('justPublished');
    // looks something like `https://rain.okta1.com`
    const linkedOrgUrl = currentUser ? currentUser.get('company.linkedOrgUrl') : undefined;
    // takes the above and creates something like `https://rain-admin.okta1.com/admin/apps/submission`
    const firstDot = linkedOrgUrl ? linkedOrgUrl.indexOf('.') : undefined;
    const oinWizardUrl = linkedOrgUrl && firstDot !== -1 ? linkedOrgUrl.substring(0, firstDot) +
        '-admin' + linkedOrgUrl.substring(firstDot) + '/admin/apps/submission' : undefined;

    return {
      id: this.model.get('id'),
      appIcon: this.model.get('appIcon') || '/img/noLogo.png',
      version: this.model.get('version'),
      dashboardStatusColor: this.model.get('dashboardColor'),
      dashboardStatusLabel: this.model.get('dashboardLabelUpper'),
      dashboardTitle: this.model.get('dashboardTitle'),
      updatedDate: this.model.get('updatedDate'),
      publishedDate: this.model.get('publishedDate'),
      showUnderReview: this.model.get('showUnderReview'),
      showPublished: this.model.get('showPublished'),
      showFootBanner: showFootBanner,
      prevVersion: this.model.get('version') - 1,
      justPublished: justPublished,
      noFooter: !showFootBanner && !justPublished,
      isSubmitted: status === 'SUBMITTED',
      isSubmittedFeedback: status === 'SUBMITTED_FEEDBACK',
      isOktaQANeeded: status === 'OKTA_QA_NEEDED',
      isOktaQAFeedback: status === 'OKTA_QA_FEEDBACK',
      isFinalReview: status === 'FINAL_REVIEW',
      isFirstVersion: this.model.get('version') === 1,
      isNewDraft: status === 'NOT_SUBMITTED' && this.model.get('version') === 1,
      isForkedDraft: status === 'NOT_SUBMITTED' && this.model.get('version') !== 1,
      isDraft: status === 'NOT_SUBMITTED',
      isDiscarded: status === 'DISCARDED',
      isDeprecated: status === 'DEPRECATED',
      isDraftOrDiscarded: status === 'DISCARDED' || status === 'NOT_SUBMITTED',
      dueDate: this.model.get('dueDate'),
      email: currentUser ? currentUser.get('email') : undefined,
      updateAppDisabled: !this.options.latestVersion && this.options.app.get('appConfigurations').length !== 1,
      oinWizardUrl: oinWizardUrl,
    };
  },

  events: {
    'click #publish-submission': '_publishSubmission',
    'click #update-app': '_updateApp',
    'click #view-version': '_viewVersion',
  },

  modelEvents: {
    change: 'onModelChange',
  },

  _publishSubmission() {
    this.model.publish();
  },

  _updateApp() {
    this.options.createNewVersion(this.model);
  },

  _viewVersion() {
    Navigation.navigateTo('/app-integration/' + this.model.get('id'));
  },

  onModelChange() {
    if (this.model.get('status') === 'DELETED') {
      this.remove();
      this.options.app.trigger('appVersionDeleted', this.model);
    } else {
      this.render();
      if (this.progressTracker) {
        this.progressTracker.setStep(this.model.get('dashboardStep'));
      }
    }
  },

  /**
   * Return the steps required by this submission.
   * SAML submissions don't have the customer-test step.
   * @returns {*[]} Array of steps
   * @private
   */
  _getSteps() {
    const steps = [{ title: INITIAL_REVIEW }, { title: OKTA_QA }];

    if (this.model.get('isCustomerTestRequired')) {
      steps.push({ title: CUSTOMER_TEST });
    }
    steps.push.apply(steps, [{ title: READY_TO_PUBLISH }]);
    return steps;
  },

  initialize() {
    this.add(new DeleteAppButtonView({ model: this.model }), '.torii-delete-app');

    // while under review, we include a progress tracker
    if (this.model.get('showUnderReview')) {
      // customer test is not used for SAML submissions
      this.progressTracker = new ProgressTrackerView({
        steps: this._getSteps(),
        step: this.model.get('dashboardStep'),
      });
      this.add(this.progressTracker, '.progress-tracker');
    }

    // when published, we include the resources drop-down and integration-type indicators
    else if (this.model.get('showPublished')) {
      const integrationType = this.model.get('integrationType') || [];
      // oidc, scim, saml (ordered according to best practices, see OKTA-558424)


      if (hasFeature('ISV_PORTAL_OIDC_SUPPORT')) {
        this.add(
          new IntegrationTypeView({ type: 'OIDC', types: integrationType }),
          '.integration-section'
        );
      }
      this.add(
        new IntegrationTypeView({ type: 'SCIM', types: integrationType }),
        '.integration-section'
      );
      this.add(
        new IntegrationTypeView({ type: 'SAML', types: integrationType }),
        '.integration-section'
      );
      if (hasFeature('OAUTH_ADMIN_CONSENT_DIALOG')) {
        this.add(
          new IntegrationTypeView({ type: 'OAUTH', types: integrationType }),
          '.integration-section'
        );
      }
      if (hasFeature('ISV_PORTAL_CONNECTOR_SUBMISSIONS')) {
        this.add(
          new IntegrationTypeView({ type: 'CONNECTOR', types: integrationType }),
          '.integration-section'
        );
      }
    }

    if (this.model.get('justPublished')) {
      this.model.resetJustPublished();
    }
  },
});

/**
 * A single app, followed by a list of versions
 */
const AppView = View.extend({
  tagName: 'li',
  template: '<h1 class="app-name">{{dashboardAppName}}</h1><ol id="app-{{id}}" class="torii-app-dashboard-app"></ol>',

  modelEvents: {
    appVersionDeleted: '_deleteAppVersion',
  },
  /**
   * Returns descriptions of the versions we want to display, in the order we want to display them.
   * @param appConfigurations All the configurations, ordered latest to earliest
   * @returns {Array} Array of { model, latest } from earliest to latest
   * @private
   */
  _getVersionsToDisplay(appConfigurations) {
    const toDisplay = [];

    // iterate until we hit the most recently published

    for (let index = 0; index < appConfigurations.length; index++) {
      const config = appConfigurations[index];

      toDisplay.push({ model: config, latest: index === 0 });
      if (config.get('showPublished')) {
        break;
      }
    }

    // reverse order
    toDisplay.reverse();
    return toDisplay;
  },

  initialize() {
    // get list of versions we want to display
    this.versions = this._getVersionsToDisplay(this.model.get('appConfigurations') || []);

    // add views
    const self = this;

    this.versions.forEach((version) => {
      self.addVersionView(version.model, version.latest);
    });
  },

  /**
   * Remove the deleted AppVersion from the view and the collection
   * @param version AppVersion which was deleted
   * @private
   */
  _deleteAppVersion(version) {
    this.versions = _.reject(this.versions, (v) => {
      return v.model.id === version.get('id');
    });

    const updatedConfigs = _.reject(this.model.get('appConfigurations'), (appConfig) => {
      return appConfig.get('id') === version.get('id');
    });

    this.model.set('appConfigurations', updatedConfigs);

    // If we are deleting the one and only version, we need to remove the App and title from the view.
    if (updatedConfigs.length === 0) {
      this.remove();
      this.collection.remove(this.model);
    } else {
      this.render();
    }
  },

  addVersionView(model, latest) {
    const versionView = new VersionView({
      model: model,
      user: this.options.user,
      createNewVersion: this.options.createNewVersion,
      latestVersion: latest,
      app: this.model,
    });

    this.add(versionView, '.torii-app-dashboard-app');
  },
});

/**
 * Header at the top with the add button and infobox
 */
const DashboardHeader = View.extend({
  template: `
    <div class="float-button-right">
      <input id="add-submission" type="button" class="dashboard-button button button-primary" 
        value="Add New Submission">
    </div>
    <div class="add-submission-error-box torii-infobox torii-hide-section"></div>
  `,

  events: {
    'click #add-submission': '_addSubmission',
  },

  showError(message, errorbox) {
    this.callout = createCallout({
      type: 'error',
      title: message,
    });
    this.add(this.callout, '.add-submission-error-box');
    errorbox.removeClass('torii-hide-section');
  },

  _addSubmission() {
    const self = this;

    self.callout && self.callout.remove();
    self.callout = undefined;
    const errorbox = self.$('.add-submission-error-box');

    errorbox.addClass('torii-hide-section');
    $.ajax({
      url: '/api/v1/apps',
      method: 'POST',
      dataType: 'json',
    })
      .done((data) => {
        const appConfigurations = data && data.appConfigurations ? data.appConfigurations : [];
        const appConfiguration = appConfigurations.length ? appConfigurations[0] : undefined;

        if (appConfiguration) {
          Navigation.navigateTo('/app-integration/' + appConfiguration.id);
        } else {
          // This error condition should never happen as creating a new app will always create
          // an app configuration too
          self.showError('No app configuration found', errorbox);
        }
      })
      .fail((response) => {
        const responseJSON = response.responseJSON;
        const message =
          (responseJSON && (responseJSON.message || responseJSON.errorSummary || responseJSON.statusText)) ||
          'An unknown error occurred';

        self.showError(message, errorbox);
      });
  },
});

/**
 * The list of apps
 */
export default View.extend({
  template: '<ol class="torii-app-dashboard"><div class="torii-app-dashboard-header"></ol>',

  initialize() {
    const self = this;

    // add the dashboard header
    if (hasFeature('ISV_PORTAL_MULTIPLE_SUBMISSIONS')) {
      self.add(new DashboardHeader(), '.torii-app-dashboard-header');
    }

    // add each app to the view

    const createNewVersion = _.bind(this.createNewVersion, this);

    this.collection.each((app) => {
      self.add(
        new AppView({
          model: app,
          user: self.options.user,
          createNewVersion: createNewVersion,
          collection: self.collection,
        }),
        '.torii-app-dashboard'
      );
    });

    self.add(new ReactWrapper({ element: SelfServiceLander }));

  },

  /**
   * Create a new version off of the passed version, and navigate to the form view.
   * @param currentVersion Source of new version
   */
  createNewVersion(currentVersion) {
    currentVersion.editPublished().then((response) => {
      Navigation.navigateTo('/app-integration/' + response['id'] + '?new=true');
    });
  },

  /**
   * Body tag is in the mustache file, and shared across several views, and
   * so we use a bit of jQuery to set the light gray background color
   */
  postRender() {
    Customize.addBodyClass('torii-dashboard');
  },
});
