import ReactWrapper from './ReactWrapper';
import { UserProvider } from '../../models/common/UserProvider';

/**
 * @param {Object} options
 * @param {JSX.Element} options.children Children for the React component
 * @param {CurrentUser} [options.user] Courage user model
 * @param {string} [options.el] The HTML element to hold the React Wrapper
 */
export function createWrapperWithUserProvider({ children, user, el }) {
  return new ReactWrapper({
    el,
    element: UserProvider,
    props: {
      useCourage: true,
      user: user?.toJSON(),
      children,
    },
  });
}
