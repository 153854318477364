/**
 * For calculating how complete a model is - percent of fields set.
 *
 * Example:  var percent = new ModelCompletion(model).add('name').add('email').getPercent();
 *
 * You can pass five types of fields when adding a field set:
 *
 *  'field' - Checks model.get('field)
 *    ex: .add('email') --> true if email is set
 *
 *  [list] - Checks each field-set in the list, counting as set if all field-sets are set
 *    ex: .add([ 'name', 'password' ]) --> true if both name and password are set
 *
 *  {any: []} - Checks each field-set in the list, counting as set if any field-sets are set
 *    ex: .add({ 'any': ['email', ['name', 'password']] })
 *    --> true if either email is set -or- name and password are set
 *
 *  {branchField, branches: {}, optional} - Uses branchField to pick one of the branches, considered set if that branch
 *  is set.  If optional is set, this branch is only considered if branchField is set.
 *    ex: .add({ 'branchField': 'mode', 'branches': { 'oauth2': ['token', 'key', 'secret'], 'header': 'format' } })
 *    --> true if mode is oauth2 and token, key, and secret are set -or- mode is header and format is set -or-
 *        mode is something else. false if mode is not set.
 *
 *  function() - Calls the function, passing the model - if the function returns true the field is considered set
 *    ex: .add( function(model) { return model.get('account').contains('@'); } );
 *
 * @author Eric Carroll
 */
import { _, $ } from 'okta';

// Construct a ModelCompletion object for a given model
//
function ModelCompletion(model) {
  // save the model
  this.model = model;

  // reset the counters
  this.total = 0;
  this.set = 0;
}

// Returns true if this fieldSet should be counted at all
//
function isActive(model, fieldSet, options) {
  // an optional branch is only active if its branch-field is set
  if (isTypeBranch(fieldSet) && fieldSet.optional) {
    return isFieldSet(model, fieldSet.branchField, options);
  }

  // default is to treat it
  return true;
}

// Returns true if this fieldSet is considered set on this model
//
function isSet(model, fieldSet, options) {
  // array of fields - all must be set for the fieldSet to be considered set
  if (_.isArray(fieldSet)) {
    return fieldSet.every((field) => {
      return isSet(model, field, options);
    });
  }

  // function - call it
  if (typeof fieldSet === 'function') {
    return fieldSet(model);
  }

  // an object that specifies a list of options - considered set if any of the options is set
  if (isTypeAny(fieldSet)) {
    return fieldSet.any.some((field) => {
      return isSet(model, field, options);
    });
  }

  // an object that specifies a branchField - considered set if the picked branch is set
  if (isTypeBranch(fieldSet)) {
    return isBranchSet(model, fieldSet.branchField, fieldSet.branches, options);
  }

  // none of the above - must be a single field name
  return isFieldSet(model, fieldSet, options);
}

// Returns true if fieldSet looks like our 'any' object
//
function isTypeAny(fieldSet) {
  return $.isPlainObject(fieldSet) && _.isArray(fieldSet.any);
}

// Returns true if fieldSet looks like our 'branch' object
//
function isTypeBranch(fieldSet) {
  return $.isPlainObject(fieldSet) && _.isString(fieldSet.branchField);
}

// Returns true if the value is not an empty string
//
function isNotEmptyString(value) {
  return !_.isUndefined(value) && !_.isNull(value) && value !== '';
}

// Returns true if this field is considered set on this model
//
function isFieldSet(model, field, options) {
  const value = model.get(field);

  if (_.isArray(value)) {
    return value.length !== 0 && _.every(value, isNotEmptyString);
  } else {
    return isNotEmptyString(value) && (!options.falseMeansNotSet || value !== false);
  }
}

// Add a field whose value causes other fields to be considered
//
function isBranchSet(model, field, branches, options) {
  // if the field is not set, this branch is considered not set
  if (!isFieldSet(model, field, options)) {
    return false;
  }

  // find the value in the branches - all field sets in the branch must be set for the whole branch to be set
  const branch = model.get(field);

  if (typeof branches[branch] !== 'undefined') {
    return isSet(model, branches[branch], options);
  }

  // if the branch is not in the branches object this branch is considered set
  return true;
}

/**
 * Add a field-set that is counted as a single unit
 * @param fieldSet The field or fields to be considered
 * @param options If options.falseMeansNotSet is set, fields who's value is false are considered not set
 * @returns {ModelCompletion} Self, for easy chaining
 */
ModelCompletion.prototype.add = function(fieldSet, options) {
  options = options || {};

  // confirm it is active
  if (isActive(this.model, fieldSet, options)) {
    // increment total
    ++this.total;

    // if set, increment set count
    if (isSet(this.model, fieldSet, options)) {
      ++this.set;
    }
  }

  // for easy chaining
  return this;
};

// Calculate and return the percent of units that are set in the model
//
ModelCompletion.prototype.getPercent = function() {
  // sanity check
  if (!this.total) {
    return undefined;
  }

  return Math.floor((this.set * 100) / this.total);
};

// return constructor function
//
export default ModelCompletion;
