/**
 * For extending top-level props with the OIDC configuration props.
 *
 * @author Mark Sztainbok
 */
import AppInstancePropertyModel from './AppInstancePropertyModel';
export default {
  getProps() {
    return {
      'oidcConfigurationJson.appInstanceUrl': 'string',
      'oidcConfigurationJson.serviceType': {
        type: 'string',
        values: ['BY_THEMSELF', 'CONTACT_US'],
      },
      'oidcConfigurationJson.redirectURIs': {
        type: 'array',
        items: {
          type: 'string',
          value: '',
        },
        value: [],
      },
      'oidcConfigurationJson.initiateLoginURI': 'string',
      'oidcConfigurationJson.platform': {
        type: 'string',
        values: ['WEB', 'NATIVE', 'SPA'],
        value: 'WEB',
      },
      'oidcConfigurationJson.postLogoutRedirectURIs': {
        type: 'array',
        items: {
          type: 'string',
          value: '',
        },
        value: [],
      },
      'oidcConfigurationJson.configGuideLink': 'string',
      'oidcConfigurationJson.redirectUriVaryByTenant': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'oidcConfigurationJson.redirectUriAips': {
        type: 'array',
        minItems: 0,
        maxItems: 20,
        value: [],
        props: AppInstancePropertyModel.OidcAppInstancePropertyModel.prototype.props,
      },
      'oidcConfigurationJson.initiateLoginUriRequired': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'oidcConfigurationJson.initiateLoginUriVaryByTenant': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'oidcConfigurationJson.initiateLoginUriAips': {
        type: 'array',
        minItems: 0,
        maxItems: 20,
        value: [],
        props: AppInstancePropertyModel.OidcAppInstancePropertyModel.prototype.props,
      },
      'oidcConfigurationJson.postLogoutRedirectUriRequired': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'oidcConfigurationJson.postLogoutRedirectUriVaryByTenant': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'oidcConfigurationJson.postLogoutRedirectUriAips': {
        type: 'array',
        minItems: 0,
        maxItems: 20,
        value: [],
        props: AppInstancePropertyModel.OidcAppInstancePropertyModel.prototype.props,
      },
      'oidcConfigurationJson.connectMultipleIdP': {
        type: 'string',
        values: ['YES', 'NO'],
      },
      'oidcConfigurationJson.signInFlow.sp': {
        type: 'boolean',
        value: false,
      },
      'oidcConfigurationJson.signInFlow.idp': {
        type: 'boolean',
        value: false,
      },
      'oidcConfigurationJson.supportedOidcModes.web': {
        type: 'boolean',
        value: false,
      },
      'oidcConfigurationJson.supportedOidcModes.spa': {
        type: 'boolean',
        value: false,
      },
    };
  },
};
