/* eslint max-len: 0 */
/*eslint complexity: ["error", 7]*/
/*eslint no-useless-escape: 0*/
/*eslint no-control-regex: 0*/

/**
 * @author: Azhar Iqbal
 */

import { _ } from 'okta';
import Libphonenumber from 'google-libphonenumber';

const usernameRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")$/i;
const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
const appNameRegex = /^[\w-_()&'\. \[\]{}]+$/;
const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const dynamicUrlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([a-zA-Z0-9]+|\$\{([^}]+)\})+([\-\.]?([a-zA-Z0-9]+|\$\{([^}]+)\}))*\.[a-zA-Z]{2,10}(:[0-9]{1,5})?(\/.*)?|(\$\{([^}]+)\}(\/.*)?)$/;
// Regex for url: < protocol --->< domain name --------------------------------------->< port or path ---------------->< query param ---------->< fragment ------>
const urlRegex = /^(https?:\/\/)?(([a-zA-Z\d]([a-zA-Z\d-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}(\:\d+)?(\/[-a-zA-Z\d%_.~+\#]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(\#[-a-zA-Z\d_]*)?$/;

class SimpleValidators {
  static sizeLessThanN(n, text) {
    return _.size(text) < n || 'String length is larger than configured maximum length ' + n;
  }

  static isNotEmptyArray(array) {
    return _.isArray(array) && array.length !== 0;
  }

  static sizeLessThan255(text) {
    return SimpleValidators.sizeLessThanN(255, text);
  }

  static sizeLessThan1024(text) {
    return SimpleValidators.sizeLessThanN(1024, text);
  }

  static isEmptyOrNumberWithinBounds(val, lowerBound, upperBound) {
    return (
      val === '' ||
      val === undefined ||
      (lowerBound <= val && val <= upperBound) ||
      'Invalid number. Should be between ' + lowerBound + ' and ' + upperBound
    );
  }

  static isValidAppName(text) {
    return (
      !SimpleValidators.isNotEmptyString(text) ||
      text.search(appNameRegex) !== -1 ||
      'Invalid app name entered. Allowed characters are [A-Z, a-z, 0-9, -, _, (, ), &, \', ., [, ], {, }]'
    );
  }

  static isUrl(text) {
    return (
      !SimpleValidators.isNotEmptyString(text) ||
      text.search(urlRegex) !== -1 ||
      '\'' + text + '\' isn\'t a valid URL. For example: https://www.okta.com'
    );
  }

  static extractHostname(url) {
    if (!SimpleValidators.isNotEmptyString(url) || url.search(urlRegex) === -1) {
      return '';
    }
    let result = url.toLowerCase();
    if (result.indexOf('//') > -1) {
      result = result.split('/')[2];
    } else {
      result = result.split('/')[0];
    }
    result = result.split(':')[0];
    result = result.split('?')[0];
    return result;
  }

  static endsWithOneOf(text, suffixes) {
    return suffixes.some((suffix) => text.endsWith(suffix));
  }

  static isNonOktaUrl(text) {
    return (
      !SimpleValidators.isNotEmptyString(text) ||
      (text.search(urlRegex) !== -1 && !SimpleValidators.endsWithOneOf(
        SimpleValidators.extractHostname(text), ['.okta.com', '.oktapreview.com', '.okta-emea.com'])) ||
      '\'' + text + '\' isn\'t a valid Test account URL. Please enter your app URL, for example: https://www.atko.com/login.'
    );
  }

  static isDynamicUrl(text) {
    return (
      !SimpleValidators.isNotEmptyString(text) ||
      text.search(dynamicUrlRegex) !== -1 ||
      '\'' +
        text +
        '\' is not a valid URL. Valid example: ' +
        'http://www.okta.com or http://www.${org.variableName}-okta.com or ${org.variableName}, where the variable is a URL'
    );
  }

  static isUrlOrNa(text) {
    return (
      !SimpleValidators.isNotEmptyString(text) ||
      text.toLowerCase() === 'n/a' ||
      text.search(urlRegex) !== -1 ||
      '\'' + text + '\' is not a valid URL or N/A. Valid example: http://www.okta.com'
    );
  }

  static isEmailOrUsername(text) {
    return (
      !SimpleValidators.isNotEmptyString(text) ||
      text.search(emailRegex) !== -1 ||
      text.search(usernameRegex) !== -1 ||
      'Invalid email address or username'
    );
  }

  static isEmailPhoneOrWebsite(text) {
    return (
      !SimpleValidators.isNotEmptyString(text) ||
      text.search(emailRegex) !== -1 ||
      SimpleValidators._isPhoneNumber(text) ||
      text.search(urlRegex) !== -1 ||
      'Invalid email, phone or website'
    );
  }

  static isEmailOrPhone(text) {
    return (
      !SimpleValidators.isNotEmptyString(text) ||
      text.search(emailRegex) !== -1 ||
      SimpleValidators._isPhoneNumber(text) ||
      'Invalid email or phone'
    );
  }

  // Returns true/false, used internally in other validators
  static _isPhoneNumber(text) {
    try {
      const phoneNumberUtil = Libphonenumber.PhoneNumberUtil.getInstance();
      const number = phoneNumberUtil.parse(text, 'US');
      return phoneNumberUtil.isValidNumber(number);
    } catch (e) {
      return false;
    }
  }

  static isPhoneNumber(text) {
    const errorMsg = `'${text}' is an invalid phone number. For example: (800) 555-0000`;
    try {
      return !SimpleValidators.isNotEmptyString(text) || SimpleValidators._isPhoneNumber(text) || errorMsg;
    } catch (e) {
      return errorMsg;
    }
  }

  static isEmail(text) {
    return (
      !SimpleValidators.isNotEmptyString(text) ||
      text.search(emailRegex) !== -1 ||
      `'${text}' isn't a valid email address. For example: support@acme.com`
    );
  }

  static isNotEmptyString(text) {
    return _.isString(text) && !_.isEmpty(text);
  }

  static arrayIsNotEmpty(array) {
    return SimpleValidators.isNotEmptyArray(array) || 'At least one value must be specified';
  }

  static stringIsNotEmpty(text) {
    return SimpleValidators.isNotEmptyString(text) || 'Empty string is not allowed';
  }

  static arrayHasNoEmptyStrings(array) {
    return (
      (_.size(array) === 1 && array[0] === '') ||
      _.every(array, (val) => {
        return !_.isString(val) || SimpleValidators.isNotEmptyString(val);
      }) ||
      'Empty strings are not allowed'
    );
  }

  static supportContactsHasNoEmptyStrings(array) {
    return (
      (_.size(array) === 1 && array[0].data === '') ||
      _.every(array, (val) => {
        return !_.isString(val.data) || SimpleValidators.isNotEmptyString(val.data);
      }) ||
      'Empty strings are not allowed'
    );
  }
  //catalog editor does not allow for first entry to be empty
  static catalogSupportContactsHasNoEmptyStrings(array) {
    return (
      _.every(array, (val) => {
        return !_.isString(val.data) || SimpleValidators.isNotEmptyString(val.data);
      }) || 'Empty strings are not allowed'
    );
  }

  static arrayHasNoDuplicates(array) {
    return _.uniq(array).length === array.length || 'Duplicate values are not allowed';
  }

  static statementsNoDuplicateName(statements) {
    const names = _.pluck(statements, 'name');
    return _.uniq(names).length === names.length || 'Duplicated attribute names are not allowed';
  }

  static arrayHasValidUrls(array) {
    const errors = [];
    _.each(array, (val) => {
      const result = SimpleValidators.isUrl(val);
      if (_.isString(result)) {
        errors.push(result);
      }
    });

    return _.isEmpty(errors) || errors;
  }

  static arrayHasValidDynamicUrls(array) {
    const errors = [];
    _.each(array, (val) => {
      const result = SimpleValidators.isDynamicUrl(val);
      if (_.isString(result)) {
        errors.push(result);
      }
    });

    return _.isEmpty(errors) || errors;
  }

  static isValidUUID(text) {
    return !SimpleValidators.isNotEmptyString(text) || text.search(uuidRegex) !== -1 || 'Invalid UUID';
  }

  static validRequestableSSOUrls(requestableUrls) {
    const urls = _.pluck(requestableUrls, 'url');
    const indexes = _.pluck(requestableUrls, 'urlIndex');

    // Check if Indexes have been filled out
    const urlsEntered = _.every(urls, (val) => {
      return SimpleValidators.isNotEmptyString(val);
    });
    if (!urlsEntered) {
      return 'Empty URLs are not allowed';
    }

    // Check if Indexes have been filled out
    const indexesEntered = _.every(indexes, (val) => {
      return _.isNumber(val);
    });
    if (!indexesEntered) {
      return 'Empty Index values are not allowed';
    }

    // Check Duplicate values for URL
    if (_.uniq(urls).length !== urls.length) {
      return 'Duplicated URLs are not allowed';
    }

    // Check Duplicate values for Index
    if (_.uniq(indexes).length !== indexes.length) {
      return 'Duplicated Index values are not allowed';
    }

    // Check if Index values are positive
    const isPositiveIndexes = _.every(indexes, (val) => {
      return _.isNumber(val) && val >= 0;
    });
    if (!isPositiveIndexes) {
      return 'Negative Index values are not allowed';
    }

    // Check if URLs are valid
    const urlErrors = SimpleValidators.arrayHasValidDynamicUrls(urls);
    if (_.isArray(urlErrors)) {
      return urlErrors;
    }
  }
}

export default SimpleValidators;
