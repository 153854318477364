import { Menu, MenuItem } from '@mui/material';
import { Button, ChevronDownIcon, UserIcon } from '@okta/odyssey-react-mui';
import { useTranslation } from '@okta/tools.react-i18n';
import { useState, MouseEvent } from 'react';
import { User } from '../../models/common/User';
import { navigateTo } from '../../utils/Navigate';

export const AccountDropdown = ({ name }: Pick<User, 'name'>) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    navigateTo('/logout');
  };

  return (
    <>
      <Button
        id="account-dropdown"
        variant="floating"
        startIcon={<UserIcon />}
        endIcon={<ChevronDownIcon />}
        onClick={handleClick}
      >
        {name}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={logOut}>{t('topBar.dropdown.signOut')}</MenuItem>
      </Menu>
    </>
  );
};
