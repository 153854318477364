import { useQuery } from '@tanstack/react-query';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { getCookie } from '../../utils/Cookies';
import { User } from './User';

export interface UserContextState {
  signedIn: boolean;
  user?: User;
  csrfToken?: string;
  canManageAppSubmissions?: boolean;
}

const NOT_SIGNED_IN_STATE: UserContextState = { signedIn: false };

const UserContext = createContext<UserContextState>(NOT_SIGNED_IN_STATE);

export function useUser() {
  return useContext(UserContext);
}

function canUserManageAppSubmissions(user: User) {
  return ['ISVPORTAL_FULL', 'ISVPORTAL_LIMITED'].includes(user?.roleName);
}

function createUserContext(user: User): UserContextState {
  return {
    signedIn: true,
    user,
    csrfToken: getCookie('csrf'),
    canManageAppSubmissions: canUserManageAppSubmissions(user),
  };
}

export interface UserProviderProps extends PropsWithChildren {
  useCourage?: boolean;
  /** Converted from courage model */
  user?: User;
}

export const UserProvider = ({ children, useCourage, user }: UserProviderProps) => {
  const { isLoading, isError, data } = useQuery({
    queryKey: ['user'],
    enabled: !useCourage,
    queryFn: async () => {
      const response = await fetch('/api/v1/current-user');
      if (!response.ok) {
        return NOT_SIGNED_IN_STATE;
      }

      const user: User = await response.json();
      return createUserContext(user);
    },
  });

  const contextValue = useMemo(() => {
    if (useCourage) {
      return user === undefined ? NOT_SIGNED_IN_STATE : createUserContext(user);
    }
    return isLoading || isError ? NOT_SIGNED_IN_STATE : data;
  }, [useCourage, user, isLoading, isError, data]);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
