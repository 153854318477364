interface ConfigWindow extends Window {
  _config: { [key: string]: string | string[] };
}

declare let window: ConfigWindow;

/**
 * Gets a Torii configuration value if it was annotated with @UIConfiguration
 * @param  {String} key
 * @return {String || String[]}
 */
function getConfig(key : string) {
  window._config = window._config || {};
  return window._config[key];
}

export { getConfig };
