import { styled } from '@mui/material';
import { Box, Button as OdysseyButton, ButtonProps, Typography } from '@okta/odyssey-react-mui';

export const Icon = styled('img')({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '2rem',
  paddingTop: '1.5rem',
}));

export const Text = styled(Typography)({
  fontSize: '1.15rem',
});

export const Button = (props: ButtonProps) => (
  <Box sx={{ textAlign: 'center', paddingTop: '3rem', paddingBottom: '1rem' }}>
    <OdysseyButton {...props}>{props.children}</OdysseyButton>
  </Box>
);
