/**
 * @author Eric Carroll
 */
const URI_PATTERN = RegExp('^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?');

// from URI spec
interface URI {
  protocol?: string,
  hostname?: string,
  port?: number,
  path?: string,
  query?: string,
  fragment?: string
}

/**
 * Parse a URI into its bits. We can't use Javascript URL class because it can't parse uris without a protocol.
 *
 * Note that 'www.acme.test' will give you an empty hostname, and 'www.acme.test' as the path.
 * '//www.acme.test' will give 'www.acme.test' as the hostname.
 */
export function parse(uri: string): URI {
  const match = uri.match(URI_PATTERN);
  let hostname = match[4];

  // if path includes a port, split them
  let port: number = undefined;

  if (hostname) {
    const portAt = hostname.indexOf(':');

    if (portAt >= 0) {
      port = parseInt(hostname.slice(portAt + 1));
      hostname = hostname.slice(0, portAt);
    }
  }

  return {
    protocol: match[2],
    hostname,
    port,
    path: match[5],
    query: match[7],
    fragment: match[9],
  };
}

/**
 * Get a displayable hostname from a URI.
 * Returns the hostname if available, the passed URI otherwise.
 * @returns Displayable hostname
 */
export function getDisplayHostname(uri: string): string {
  const parsed = parse(uri);

  // use hostname if available, passed URI otherwise.
  return parsed.hostname || uri;
}
