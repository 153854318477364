/**
 * @author: Azhar Iqbal
 */

import { _ } from 'okta';
import SimpleValidators from './SimpleValidators';

const aipVariableRegex = /\${[^}]+}/g;

class ComplexValidators {
  static validSamlVariablesUsed(model, fieldName, fieldValue) {
    // added with addition of AIPs to SLO section
    if (fieldName === 'samlConfigurationJson.spSingleSignOut.sloUrl') {
      if (model.get('samlConfigurationJson.spSingleSignOut.doesSloUrlVaryPerTenant') === 'YES') {
        const createdAips = model.get('samlConfigurationJson.sloAppInstanceProperties');
        return ComplexValidators._verifyAipVariables(createdAips, fieldValue);
      }
    }
    // original AIP handling (acs url)
    else {
      if (model.get('samlConfigurationJson.doesAcsUrlVaryPerTenant') === 'YES') {
        const createdAips = model.get('samlConfigurationJson.appInstanceProperties');
        return ComplexValidators._verifyAipVariables(createdAips, fieldValue);
      }
    }
  }

  static arrayHasValidSamlVariablesUsed(model, fieldName, array) {
    const urls = _.pluck(array, 'url');

    // Check if URLs contain valid SamlVariables
    return ComplexValidators.validSamlVariablesUsed(model, fieldName, urls);
  }

  static validScimVariablesUsed(model, fieldName, fieldValue) {
    if (model.get('scimConfigurationJson.doesFixedUrlVaryByTenant') === 'YES') {
      const createdAips = model.get('scimConfigurationJson.appInstanceProperties');
      return ComplexValidators._verifyAipVariables(createdAips, fieldValue);
    }
  }

  static validOidcVariablesUsedForInitiateLoginUri(model, fieldName, fieldValue) {
    if (
      model.get('oidcConfigurationJson.initiateLoginUriRequired') === 'YES' &&
      model.get('oidcConfigurationJson.initiateLoginUriVaryByTenant') === 'YES'
    ) {
      const createdAips = model.get('oidcConfigurationJson.initiateLoginUriAips');
      return ComplexValidators._verifyAipVariables(createdAips, fieldValue);
    }
  }

  static validOidcVariablesUsedForRedirectUri(model, fieldName, fieldValue) {
    if (model.get('oidcConfigurationJson.redirectUriVaryByTenant') === 'YES') {
      const createdAips = model.get('oidcConfigurationJson.redirectUriAips');
      return ComplexValidators._verifyAipVariables(createdAips, fieldValue);
    }
  }

  static validOidcVariablesUsedForPostLogoutUri(model, fieldName, fieldValue) {
    if (
      model.get('oidcConfigurationJson.postLogoutRedirectUriRequired') === 'YES' &&
      model.get('oidcConfigurationJson.postLogoutRedirectUriVaryByTenant') === 'YES'
    ) {
      const createdAips = model.get('oidcConfigurationJson.postLogoutRedirectUriAips');
      return ComplexValidators._verifyAipVariables(createdAips, fieldValue);
    }
  }

  static _verifyAipVariables(createdAips, fieldValue) {
    if (_.isUndefined(fieldValue)) {
      return;
    }

    const variables = _.pluck(createdAips, 'variableName');
    let referencedVariables = [];
    // If array, find the variables from each of the values in the array
    if (_.isArray(fieldValue)) {
      referencedVariables = _.flatten(
        _.compact(
          _.map(fieldValue, (value) => {
            return value.match(aipVariableRegex);
          })
        )
      );
    } else {
      referencedVariables = fieldValue.match(aipVariableRegex);
    }
    const unknownVariables = _.difference(_.uniq(referencedVariables), variables);

    if (!_.isEmpty(unknownVariables)) {
      const prefix = _.size(unknownVariables) > 1 ? 'Unknown variables: ' : 'Unknown variable: ';
      return prefix + unknownVariables.join(', ') + '. Use an existing variable or define a new variable.';
    }
  }

  static verifySupportContacts(model, fieldName, fieldValue) {
    let errorMsg;
    fieldValue.forEach(contact => {
      let res;
      switch (contact.type) {
      case 'EMAIL':
        res = SimpleValidators.isEmail(contact.data);
        break;
      case 'PHONE_NUMBER':
        res = SimpleValidators.isPhoneNumber(contact.data);
        break;
      case 'URL':
        res = SimpleValidators.isUrl(contact.data);
        break;
      default:
        res = 'Unrecognized data type.';
      }
      if (res !== true) {
        errorMsg = res;
      }
    });
    return errorMsg;
  }

  static verifyOIDCSupportedModes(model) {
    let errorMsg;
    const spa = model.get('oidcConfigurationJson.supportedOidcModes.spa');
    const web = model.get('oidcConfigurationJson.supportedOidcModes.web');
    //both check boxes cannot be selected
    if (web && spa) {
      errorMsg = 'Only one OIDC mode option can be selected';
    }
    return errorMsg;
  }
}

export default ComplexValidators;
