import { Link, Typography, Box, InputBase, InputAdornment } from '@okta/odyssey-react-mui';
import { Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from '@okta/tools.react-i18n';

export const SelfServiceLander = () => {
  const { t } = useTranslation();
  return (
    // Need to set maxWidth prop to false, or it will override width
    <Box
      className="SelfServiceLander"
      sx={{
        maxWidth: 1000,
        backgroundColor: 'white',
        border: '1px solid #ddd',
        padding: 5,
        '.MuiLink-root': {
          lineHeight: 2.3,
        },
      }}
    >
      <Typography variant="h5" sx={{ paddingBottom: '1.2em' }}>
        {t('dashboard.isv.selfService.title')}
      </Typography>
      <Grid container spacing={2} sx={{ paddingBottom: '5em' }}>
        <Grid item sm={6} display="flex" flexDirection="column">
          <Typography variant="h6">{t('dashboard.isv.selfService.issues.title')}</Typography>
          <Link href="https://developer.okta.com/docs/guides/build-sso-integration/openidconnect/main/" target="_blank">
            {t('dashboard.isv.selfService.issues.oidc')}
          </Link>
          <Link
            href="https://developer.okta.com/docs/guides/scim-provisioning-integration-overview/main/"
            target="_blank"
          >
            {t('dashboard.isv.selfService.issues.scim')}
          </Link>
          <Link href="https://developer.okta.com/docs/guides/build-sso-integration/saml2/main/" target="_blank">
            {t('dashboard.isv.selfService.issues.saml')}
          </Link>
          <Link href="https://developer.okta.com/docs/guides/build-api-integration/main/" target="_blank">
            {t('dashboard.isv.selfService.issues.oauth')}
          </Link>
          <Link href="https://help.okta.com/okta_help.htm?type=wf&id=ext-third-party-overview" target="_blank">
            {t('dashboard.isv.selfService.issues.connector')}
          </Link>
          <Link href="https://developer.okta.com/docs/guides/submit-app-prereq/" target="_blank">
            {t('dashboard.isv.selfService.issues.reqs')}
          </Link>
        </Grid>
        <Grid item sm={6} display="flex" flexDirection="column">
          <Typography variant="h6">{t('dashboard.isv.selfService.learn.title')}</Typography>
          <Link href="https://developer.okta.com/docs/concepts/how-okta-works/" target="_blank">
            {t('dashboard.isv.selfService.learn.okta')}
          </Link>
          <Link href="https://developer.okta.com/docs/concepts/oauth-openid/" target="_blank">
            {t('dashboard.isv.selfService.learn.oidc')}
          </Link>
          <Link href="https://developer.okta.com/docs/concepts/scim/" target="_blank">
            {t('dashboard.isv.selfService.learn.scim')}
          </Link>
          <Link href="https://developer.okta.com/docs/concepts/saml/" target="_blank">
            {t('dashboard.isv.selfService.learn.saml')}
          </Link>
          <Link href="https://developer.okta.com/docs/guides/oin-api-service-overview/" target="_blank">
            {t('dashboard.isv.selfService.learn.oauth')}
          </Link>
          <Link href="https://developer.okta.com/docs/guides/submit-app/wfconnector/main/#submit-an-integration"
            target="_blank">
            {t('dashboard.isv.selfService.learn.connector')}
          </Link>

        </Grid>
      </Grid>
      <Typography variant="h6">{t('dashboard.isv.selfService.search.title')}</Typography>
      <InputBase
        type="search"
        placeholder={t('dashboard.isv.selfService.search.placeholder')}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        sx={{ width: '50%' }}
        onKeyDown={(e) => {
          const searchTerm = (e.target as HTMLInputElement).value.trim();
          if (e.key === 'Enter' && searchTerm !== '') {
            window.open(`https://developer.okta.com/search/#q=${encodeURIComponent(searchTerm)}`, '_blank');
          }
        }}
      />
    </Box>
  );
};
