import { _, View } from 'okta';
export default View.extend({
  className: 'data-list-sidebar',
  template:
    '\
      <h3 class="data-list-sidebar-heading">INTEGRATION TYPE</h3>\
      <div class="sidebar-dropdown-wrap clearfix">\
        <select class="sidebar-dropdown">\
          {{#each options}}\
            <option value="{{@key}}">{{this}}</option>\
          {{/each}}\
        </select>\
      <div>\
    ',
  events: {
    'change select'(event, data) {
      this.state.unset('search');
      this.state.set('integrationType', data.selected);
    },
  },

  initialize() {
    this.listenTo(this.state, 'change:integrationType', this._updateOption);
  },

  _updateOption(state, integrationType) {
    // update selected option for chosen widget
    this.$('select')
      .val(integrationType)
      .trigger('liszt:updated');
  },

  getTemplateData() {
    return {
      options: {
        ALL: 'All',
        OIDC: 'OIDC',
        SCIM: 'SCIM',
        SAML: 'SAML',
        CONNECTOR: 'Connector',
        OAUTH: 'API Service',
      },
    };
  },
  postRender() {
    const self = this;

    _.defer(() => {
      self.$('select').chosen({
        width: '100%',
        // eslint-disable-next-line camelcase
        disable_search: true,
      });
    });
  },
});
